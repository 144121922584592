import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import IsLoadingHOC from './../components/IsLoadingHOC';
import { pointHistoryGet } from '../redux/actions';
import { Container, Table } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import '../assets/css/pagination.scss';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
const PointHistory = props => {
  const { t } = useTranslation();
  const setLoading = props.setLoading;
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [totalPoint, setTotalPoint] = useState(0);
  const user = useSelector(({ user }) => user);
  const getData = async () => {
    props
      .pointHistoryGet()
      .then(response => {
        if (response['data']) {
          const resData = response.data.content;
          let totp = 0;
          resData.forEach(val => {
            if (val.ledger === 'credit') {
              totp += val.points;
            } else {
              totp -= val.points;
            }
          });
          setTotalPoint(totp);
          setPageCount(Math.ceil(resData.length / perPage));

          const slice = resData.slice(offset, offset + perPage);
          const postData = slice.map((item, index) => {
            let itemDate = new Date(item.date);
            let newDate = itemDate.toLocaleDateString();
            return { description: item.description, newDate: newDate, ledger: item.ledger === 'credit' ? '+' : '-', points: item.points };
          });
          setData(postData);
        }
        setLoading(false);
      })
      .catch(ex => {
        console.log('error in Point List Api', ex);
        setLoading(false);
      });
  };

  const handlePageClick = e => {
    const selectedPage = e.selected;
    setOffset(selectedPage * perPage);
  };

  useEffect(() => {
    setLoading(true);
    getData();
    //eslint-disable-next-line
  }, [offset]);
  return (
    <>
      <div className="user-pages">
        <div className="w-100 mb-5 position-relative" id="rd">
          <div className="">
            <div className="reward-banner">
              <div className="top-banner">
                <img className="banner-img d-none d-sm-block" src="/public/assets/images/homepage/desktop_banner_without_button.png" />
                <img className="w-100 banner-img d-block d-sm-none" src="/public/assets/images/homepage/mobile_banner_without_button.png" />
                <div className="container">
                  <div className="d-sm-flex card-img-overlay">
                    <div className="col-sm-6 earnpoint-hero-banner white">
                      <h1 className="header-1">Plaid Perks™ is being discontinued.</h1>
                      <h4>
                        <strong>
                          We are no longer accepting new receipt submissions. You may use your available points to redeem rewards at PlaidPerks.com, while supplies last, until September 8, 2023. Click{' '}
                          <Link to="/faq" className="white-link">
                            here
                          </Link>{' '}
                          to learn more.
                        </strong>
                        {/* <strong>
                          Members have until August 21, 2023, to submit valid receipts or points codes to earn points and until September 8, 2023 to redeem their points for rewards at PlaidPerks.com. Click{' '}
                          <Link to="/faq" className="white-link">
                            here
                          </Link>{' '}
                          to learn more.
                        </strong> */}
                      </h4>
                      <p>Members will receive an email with instructions for how to redeem points after September 8, 2023.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section portal-history post-login">
          <Container>
            <div className="mb-3">
              <div className="d-flex justify-content-center">
                <h2>
                  {' '}
                  <Trans>Manage Your Account</Trans>{' '}
                </h2>
              </div>
              <div className="d-flex justify-content-center">
                <h6>
                  Current Point Balance: <strong>{user.pointBalance}</strong>
                </h6>
              </div>
              <div className="d-flex justify-content-between mt-3 mb-5 mya-tabs">
                <Link to="/points-history" className="btn btn-primary">
                  Points History
                </Link>
                <Link to="/my-rewards" className="btn btn-light">
                  My Rewards
                </Link>
                <Link to="/profile" className="btn btn-light">
                  Profile
                </Link>
              </div>
            </div>
            <Table>
              <thead>
                <tr>
                  <th>
                    <Trans>Description</Trans>
                  </th>
                  <th>
                    <Trans>Date</Trans>
                  </th>
                  <th>
                    <Trans>Points</Trans>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  <>
                    {data.map((row, i) => {
                      return (
                        <tr key={i}>
                          <td>{row.description}</td>
                          <td>{row.newDate}</td>
                          <td>
                            {row.ledger}
                            {row.points}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td colSpan="3">
                      <center>
                        <Trans>No point history found</Trans> !
                      </center>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <ReactPaginate previousLabel={t('Prev')} nextLabel={t('Next')} breakLabel={'...'} breakClassName={'break-me'} pageCount={pageCount} marginPagesDisplayed={2} pageRangeDisplayed={5} onPageChange={handlePageClick} containerClassName={'pagination'} subContainerClassName={'pages pagination'} activeClassName={'active'} />
          </Container>
        </div>
      </div>
      <div className="w-100">
        <div className="faq-wrapper">
          <div className="faq-bot-banner">
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-md-5 d-flex align-items-center">
                  <div className="">
                    <h2 className="text-left">Have questions about your account?</h2>
                    <a href="/faq" className="btn btn-primary">
                      Go to FAQs
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { pointHistoryGet })(IsLoadingHOC(PointHistory, 'Loading....'));
