import React, { Fragment } from "react";
import { logo } from "../data/index";
import { Link } from "react-router-dom";
const Logo = () => {
	return (
		<Fragment>
			<Link id="href-logo" to="/" className="navbar-brand">
				<img alt="Plaid Perks logo" src={logo} />
			</Link>
			<a id="mutrena-logo" href="https://www.nutrenaworld.com/" target="_blank">
				<img src="/public/assets/images/nutrena-logo.png" className="nutrena_logo" alt="Nutrena Logo" />
			</a>
		</Fragment>
	);
};
export default Logo;
