export const portalPoints = {
  statusCode: 200,
  headers: {
    'Content-type': 'application/json',
  },
  body: {
    portalPoints: [
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-video-50d4675ec91014d74907ac0b62ce526a',
        channel: 'video',
        points: 15,
        times: 1,
        actionType: 'view',
        title: 'Shrek: Silent Game Challenge',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/earnpoints/Donkey_Shrek_Silent_Game.png',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-video-21c87fc80c7c2de1e98fcc8cfd490be4',
        channel: 'video',
        points: 15,
        times: 1,
        actionType: 'view',
        title: 'The Mauritanian: Trailer',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/earnpoints/The-Maurintinain.png',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-questions-1941ac23f317226aaef853e0f98c6e12',
        channel: 'questions',
        points: 5,
        times: 1,
        actionType: 'survey',
        title: 'Take our Shrek poll',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/earnpoints/Shrek_May2021_Poll_tout.png',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-questions-7b6b6822fc5ad7109fc9f50eb93cbeb6',
        channel: 'questions',
        points: 25,
        times: 1,
        actionType: 'survey',
        title: 'Liam Neeson Quiz',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/earnpoints/LiamNeeson_quiz.png',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-video-2afa9b6277aadffabc3271a395d3e9de',
        channel: 'video',
        points: 15,
        times: 1,
        actionType: 'view',
        title: 'The Marksman: Extended Preview',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/earnpoints/Marksmen-ExtendedPreview.png',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-video-4cc87a300609dafe98f3e4ca797965c1',
        channel: 'video',
        points: 15,
        times: 1,
        actionType: 'view',
        title: "Shrek: Who's The Greatest Hero?",
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/earnpoints/shrek_whos-the-greatest-hero.png',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-questions-58d4d5c9ef89ad05ee7fd2b124e46281',
        channel: 'questions',
        points: 25,
        times: 1,
        actionType: 'survey',
        title: 'Shrek’s Ultimate Fan Quiz\n',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/earnpoints/ShrekAnniv_Quiz_EarnPoints_tout.png',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-video-3b068181d9f8b8ea16de2ea5fb41239b',
        channel: 'video',
        points: 15,
        times: 1,
        actionType: 'view',
        title: 'How To Train Your Dragon: The Hidden World: Trailer',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/actions/HTTYD_BTS_Rewards_tout_01.jpg',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-video-64459dc10ac31a7a7fba9fc819161ee0',
        channel: 'video',
        points: 15,
        times: 1,
        actionType: 'view',
        title: 'Parasite: Trailer',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/actions/Parasite_Rewards_tout.jpg',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-questions-20bc217faddfb1aace9f84538bc6209a',
        channel: 'questions',
        points: 25,
        times: 1,
        actionType: 'survey',
        title: "Universal's Award-Winning Films Quiz",
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/Surveys/EarnPoints_UniversalAwards_Quiz_tout.jpg',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-video-8f70c0a7281b2d598a72d8b0953a618f',
        channel: 'video',
        points: 15,
        times: 1,
        actionType: 'view',
        title: '1917: Behind-The-Scenes',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/actions/1917_BTS_Rewards_tout_01.jpg',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-questions-87f67f44a1f7a2b6effa977fcc8fccc',
        channel: 'questions',
        points: 25,
        times: 1,
        actionType: 'survey',
        title: 'DreamWorks Animation Award-Winning Films Quiz',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/actions/DreamworksAwardWinning_Quiz_EarnPoints_tout.jpg',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-video-a73517ffb7dfa407be075a92f51a912d',
        channel: 'video',
        points: 15,
        times: 1,
        actionType: 'view',
        title: 'Greenbook: Behind-The-Scenes',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/actions/Greenbook_BTS_Rewards_tout.jpg',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-questions-d73cb9011f117052754c2a84d4a13e13',
        channel: 'questions',
        points: 25,
        times: 1,
        actionType: 'survey',
        title: 'Awards Season Survey',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/Surveys/EarnPoints_AwardsSeason_Survery_tout.jpg',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-video-5f624bb676dbf3aa19ba58b464d28173',
        channel: 'video',
        points: 15,
        times: 1,
        actionType: 'view',
        title: 'Half Brother: Extended Preview',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/actions/HalfBrother_ExtendedPreiewjpg.jpg',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-video-a5d62429494ed0286979364b1a4af337',
        channel: 'video',
        points: 15,
        times: 1,
        actionType: 'view',
        title: 'The Croods: A New Age - Behind The Scenes',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/actions/Croods_BTS_Rewards_tout_01.jpg',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-video-54872a030addef34087d7574929f64d',
        channel: 'video',
        points: 15,
        times: 1,
        actionType: 'view',
        title: 'News of The World: Behind-The-Scenes',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/actions/NewsOTW_BTS.jpg',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-video-c4550a6c5683f45d345d78d955b133d2',
        channel: 'video',
        points: 15,
        times: 1,
        actionType: 'view',
        title: 'Greenland: Trailer',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/actions/Greenland_Trailer.jpg',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-video-989981be0e38bfb091293ab0bb60aa8',
        channel: 'video',
        points: 15,
        times: 1,
        actionType: 'view',
        title: 'The Croods: A New Age - Extended Preview',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/rewards/CroodsTrailer_Rewards.jpg',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-video-dade7e157b7da42eb4f6c14ccf7e917f',
        channel: 'video',
        points: 15,
        times: 1,
        actionType: 'view',
        title: 'Boss Baby: Film Clip',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/dreamworks/Boss-baby_Rewards_tout_template_new_01.jpg',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-video-6ddd7130e0fef97c1956ff41c6f05506',
        channel: 'video',
        points: 15,
        times: 1,
        actionType: 'view',
        title: 'Trolls World Tour: Country & Funk Trolls Bonus Clip',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/dreamworks/Trolls-BTS_Rewards_tout_template_new_01.jpg',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-video-8d6dd1cb503f15e5283e6736528408a1',
        channel: 'video',
        points: 15,
        times: 1,
        actionType: 'view',
        title: 'How To Train Your Dragon: Extended preview',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/dreamworks/How-to-train-dragon_Extendedpreview_Rewards.jpg',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-microsite-570fcca99d88877a158dd18017e95f4c',
        channel: 'microsite',
        points: 5,
        times: 1,
        actionType: 'checkin',
        title: 'We could all use some laughs! ',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/actions/Nov_OfferPage_EarnPoints.jpg',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-microsite-cb5f0279e5993a703fd0482fdb51a397',
        channel: 'microsite',
        points: 20,
        times: 1,
        actionType: 'checkin',
        title: 'Halloween Memes',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/actions/Memes/HalloweenMemes_HeadGraphic.jpg',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-facebook-61354c157613653fce1a76b8920a6dd2',
        channel: 'facebook',
        points: 15,
        times: 1,
        actionType: 'pagelike',
        title: 'Digital Movie Deals',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/actions/DIG+DEALS+FB+1000x1000.png',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-facebook-25224f272981ed9f7caa65d936a67195',
        channel: 'facebook',
        points: 15,
        times: 1,
        actionType: 'pagelike',
        title: 'Movies Anywhere',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/actions/MA+1000x1000.png',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-facebook-8dedc7d2bf41fbaadd98f14af59c2251',
        channel: 'facebook',
        points: 15,
        times: 1,
        actionType: 'pagelike',
        title: 'Gruv',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/actions/GRUV+FB1000x1000.png',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-facebook-730c4d1a43c6452251a80967d29c3d05',
        channel: 'facebook',
        points: 15,
        times: 1,
        actionType: 'pagelike',
        title: 'Universal Studios Entertainment',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/actions/UNI+FB+1000x1000.png',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-questions-dd0f29b45402d92d24acf9480608adb9',
        channel: 'questions',
        points: 25,
        times: 1,
        actionType: 'survey',
        title: 'Tell Us About Yourself! ',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/nbc/rewardimages/lukas-blazek-mcSDtbWXUZU-unsplash+(1).jpg',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-video-8a1290808999e75cda031bce22ab11d4',
        channel: 'video',
        points: 15,
        times: 1,
        actionType: 'view',
        title: 'The Turning: Trailer ',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/nbc/actionicons/Turning_Rewards.jpg',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-microsite-999488dec0a57297863e6378ca175d9f',
        channel: 'microsite',
        points: 50,
        times: 1,
        actionType: 'contest',
        title: 'Start Earning Points for Digital Purchases!',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/actions/connectma.png',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-microsite-8ec8927236bd6f079aee6db369f103f9',
        channel: 'microsite',
        points: 50,
        times: 1,
        actionType: 'contest',
        title: 'Share your movie collection',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/actions/moviecollection.png',
      },
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-kiosk-4cbb5565ac3966fb6156ac6117ce28b5',
        channel: 'kiosk',
        points: 100,
        times: 1,
        actionType: 'checkin',
        title: 'Refer a Friend',
        cardHeaderImage: 'https://s3-us-west-2.amazonaws.com/platform3-portalv3-production/nbc/actionicons/refer-a-friend.jpg',
      },
    ],
  },
};
export const contests = {
  statusCode: 200,
  headers: {
    'Content-type': 'application/json',
  },
  body: {
    portalPoints: [
      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-questions-7b6b6822fc5ad7109fc9f50eb93cbeb6',
        channel: 'questions',
        points: 25,
        times: 1,
        actionType: 'survey',
        title: 'Liam Neeson Quiz',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/earnpoints/LiamNeeson_quiz.png',
      },

      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-video-a5d62429494ed0286979364b1a4af337',
        channel: 'video',
        points: 15,
        times: 1,
        actionType: 'view',
        title: 'The Croods: A New Age - Behind The Scenes',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/actions/Croods_BTS_Rewards_tout_01.jpg',
      },

      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-video-8d6dd1cb503f15e5283e6736528408a1',
        channel: 'video',
        points: 15,
        times: 1,
        actionType: 'view',
        title: 'How To Train Your Dragon: Extended preview',
        cardHeaderImage: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/dreamworks/How-to-train-dragon_Extendedpreview_Rewards.jpg',
      },

      {
        compositeKey: '8be1c7b396cf9d694babd31c3202f0bc-universal-kiosk-4cbb5565ac3966fb6156ac6117ce28b5',
        channel: 'kiosk',
        points: 100,
        times: 1,
        actionType: 'checkin',
        title: 'Refer a Friend',
        cardHeaderImage: 'https://s3-us-west-2.amazonaws.com/platform3-portalv3-production/nbc/actionicons/refer-a-friend.jpg',
      },
    ],
  },
};

export const menu = {
  statusCode: 200,
  headers: {
    'Content-type': 'application/json',
  },
  body: {
    menuStructure: [
      {
        id: '343',
        description: 'Validate Purchase',
        hasSubmenu: true,
        submenu: [
          {
            id: '343',
            description: 'Submit Code',
            hasSubmenu: false,
            href: '/validate-code',
          },
          {
            id: '364',
            description: 'Digital Movie',
            hasSubmenu: false,
            href: '/manage-account/movies-anywhere',
          },
          {
            id: '324',
            description: 'Receipt Upload',
            hasSubmenu: false,
            href: '/upload-receipt',
          },
          {
            id: '334',
            description: 'Eligible Products',
            hasSubmenu: false,
            href: '/eligible-movies',
          },
        ],
      },
      {
        id: '314',
        description: 'Rewards',
        hasSubmenu: false,
        href: '/rewards',
      },
      {
        id: '214',
        description: 'Contest',
        hasSubmenu: false,
        href: '/contest',
      },
      {
        id: '564',
        description: 'Earn Points',
        hasSubmenu: false,
        href: '/earnpoints',
      },
      {
        id: '314',
        description: 'All Access',
        hasSubmenu: true,
        submenu: [
          {
            id: '343',
            description: 'my all-access',
            hasSubmenu: false,
            href: '/validate-code',
          },
          {
            id: '364',
            description: 'My Movie Features',
            hasSubmenu: false,
            href: '/extras',
          },
        ],
      },
      {
        id: '3414',
        description: 'Account',
        image: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/assets/common/perks/perk-1-icon.png',
        hasSubmenu: true,
        submenu: [
          {
            id: '2',
            description: 'my all-access',
            hasSubmenu: false,
            href: '/validate-code',
          },
          {
            id: '3',
            description: 'Points History',
            hasSubmenu: false,
            href: '/manage-account/point-history',
          },
          {
            id: '4',
            description: 'My Rewards',
            hasSubmenu: false,
            href: '/manage-account/my-rewards',
          },
          {
            id: '5',
            description: 'Profile',
            hasSubmenu: false,
            href: '/manage-account/settings',
          },
          {
            id: '6',
            description: 'Connect Movies Anywhere',
            hasSubmenu: false,
            href: '/manage-account/movies-anywhere',
          },
          {
            id: '7',
            description: 'Logout',
            hasSubmenu: false,
            href: '/logout',
          },
        ],
      },
    ],
  },
};

export const user = {
  statusCode: 200,
  headers: {
    'Content-type': 'application/json',
  },
  body: {
    compositeKey: '9689a439-6617-4c4f-98cd-ebd3fdb64731',
    clientid: '8be1c7b396cf9d694babd31c3202f0bc',
    userid: '259b3f861a2c6917bb2c45dbe6a71742',
    firstName: 'Test',
    lastName: 'User',
    email: 'earther2k@gmail.com',
    emailHash: '24afcbd33f514c7e02d23ef9232db0c',
    birth: 466239600000,
    gender: 'male',
    phone: {
      homePhone: '',
      cellPhone: '',
    },
    city: '',
    postal: '94555',
    receiveEmail: true,
    receiveSms: false,
    suspended: false,
    addressDetails: {
      address: '',
      state: '',
      suite: '',
      country: '',
      shippingInstructions: '',
    },
    additionalInfo: {
      universalFirstLoginPopUp: '',
      tier: '0',
      universalPopUpCta: '',
      username: 'earther2k',
    },
    created: 1615365850356,
    updated: 1615365850532,
  },
};

export const pointHistory = {
  statusCode: 200,
  headers: {
    'Content-type': 'application/json',
  },
  body: {
    pointHistory: [
      {
        ledger: 'credit',
        points: 15,
        description: 'You earned points for watching Parasite: Trailer',
        date: '04/23/2021 02:14',
        isAdjustment: false,
      },
      {
        ledger: 'credit',
        points: 10,
        description: 'Thanks! You did something to earn points.',
        date: '04/22/2021 07:42',
        isAdjustment: false,
      },
      {
        ledger: 'debit',
        points: 50,
        description: 'Thanks! You did something to earn points.',
        date: '04/22/2021 07:42',
        isAdjustment: false,
      },
      {
        ledger: 'debit',
        points: 2,
        description: 'Thanks! You did something to earn points.',
        date: '04/16/2021 00:25',
        isAdjustment: false,
      },
      {
        ledger: 'debit',
        points: 5,
        description: 'Thanks! You did something to earn points.',
        date: '04/09/2021 00:07',
        isAdjustment: false,
      },
      {
        ledger: 'credit',
        points: 15,
        description: 'You earned points for watching Greenbook: Behind-The-Scenes',
        date: '04/09/2021 00:07',
        isAdjustment: false,
      },
      {
        ledger: 'credit',
        points: 5,
        description: 'Thanks for participating in our Tom Hanks Poll!',
        date: '03/31/2021 00:43',
        isAdjustment: false,
      },
      {
        ledger: 'debit',
        points: 5,
        description: 'Thanks! You did something to earn points.',
        date: '03/19/2021 09:09',
        isAdjustment: false,
      },
      {
        ledger: 'credit',
        points: 15,
        description: 'You earned points for watching The Croods: A New Age - Behind The Scenes',
        date: '03/10/2021 00:50',
        isAdjustment: false,
      },
      {
        ledger: 'credit',
        points: 15,
        description: 'You earned points for watching News of The World: Behind-The-Scenes',
        date: '03/10/2021 00:48',
        isAdjustment: false,
      },
    ],
    total: 11,
  },
};

export const footerMenu = [
  {
    title: 'PROGRAM PERKS',
    id: 1,
    route: 'route',
  },
  {
    title: 'FAQ',
    id: 2,
    route: 'route',
  },
  {
    title: 'SUPPORT',
    id: 3,
    route: 'route',
  },
  {
    title: 'TERMS OF USE',
    id: 4,
    route: 'route',
  },
  {
    title: 'DO NOT SELL MY PERSONAL INFORMATION',
    id: 5,
    route: 'route',
  },
  {
    title: 'PRIVACY POLICY',
    id: 6,
    route: 'route',
  },
];

export const footerSocial = [
  {
    route: 'https://www.facebook.com/UniversalPicturesAllAccess',
    name: 'facebook',
    id: 1,
  },
  {
    route: 'https://twitter.com/UniAllAccess',
    name: 'facebook',
    id: 1,
  },
  {
    route: 'https://www.instagram.com/uniallaccess/',
    name: 'facebook',
    id: 1,
  },
];

export const footerCopyRight = {
  copyRightText: '©2021 Universal Studios. All Rights Reserved',
  poweredText: 'Powered By Tier Logic',
};

export const logo = 'https://universal.3tlstaging.com/assets/images/logo/logo-v2.png';

export const carouselData = [
  {
    img: 'https://platform3-portalv3-production.s3.amazonaws.com/uniallaccess/portal/banners/desktop/spinwinshrek_desktop.jpg',
    title: 'slider 1',
  },
  {
    img: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/portal/banners/desktop/Sweeps_YearOfMovies.jpg',
    title: 'slider 2',
  },
  {
    img: 'https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/portal/banners/desktop/boogie_desktop.jpg',
    title: 'slider 3',
  },
];

export const faqCategory = [
  {
    id: 1,
    name: 'About Plaid Perks',
    slug: 'about-plaid-perks',
    image: '/public/assets/images/faq/Tile-About.png',
    desktop: '/public/assets/images/faq/FAQs_Topics_Banners/Desktop-About.png',
    mobile: '/public/assets/images/faq/FAQs_Topics_Banners/Mobile-About.png',
  },
  {
    id: 2,
    name: 'Account Information',
    slug: 'account-information',
    image: '/public/assets/images/faq/Tile-AcctInfo.png',
    desktop: '/public/assets/images/faq/FAQs_Topics_Banners/Desktop-AcctInfo.png',
    mobile: '/public/assets/images/faq/FAQs_Topics_Banners/Mobile-AcctInfo.png',
  },
  {
    id: 3,
    name: 'Uploading Receipts',
    slug: 'uploading-receipts',
    image: '/public/assets/images/faq/Tile-Receipts.png',
    desktop: '/public/assets/images/faq/FAQs_Topics_Banners/Desktop-Receipts.png',
    mobile: '/public/assets/images/faq/FAQs_Topics_Banners/Mobile-Receipts.png',
  },
  {
    id: 4,
    name: 'Completing Activities',
    slug: 'completing-activities',
    image: '/public/assets/images/faq/Tile-Activities.png',
    desktop: '/public/assets/images/faq/FAQs_Topics_Banners/Desktop-Activities.png',
    mobile: '/public/assets/images/faq/FAQs_Topics_Banners/Mobile-Activities.png',
  },
  {
    id: 5,
    name: 'Redeeming Rewards',
    slug: 'redeeming-rewards',
    image: '/public/assets/images/faq/Tile-Rewards.png',
    desktop: '/public/assets/images/faq/FAQs_Topics_Banners/Desktop-Rewards.png',
    mobile: '/public/assets/images/faq/FAQs_Topics_Banners/Mobile-Rewards.png',
  },
  {
    id: 6,
    name: 'Good-To-Know Details',
    slug: 'good-to-know-details',
    image: '/public/assets/images/faq/Tile-Details.png',
    desktop: '/public/assets/images/faq/FAQs_Topics_Banners/Desktop-Details.png',
    mobile: '/public/assets/images/faq/FAQs_Topics_Banners/Mobile-Details.png',
  },
];
// category_slug:'account-information',
export const faqData = [
  // About Plaid Perks
  { id: 49, clicked: false, category_slug: 'about-plaid-perks', name: 'Why is Plaid Perks being discontinued?', content: 'The Nutrena® brand team has made the decision to eliminate our Nutrena® Plaid Perks™ loyalty program, effective September 9, 2023. We look forward to continuing to serve our loyal consumers and feed their animals for years to come with a focus on unique and brand-wide promotions throughout the year.' },
  { id: 50, clicked: false, category_slug: 'about-plaid-perks', name: 'Will there be a new loyalty program replacing Plaid Perks?', content: 'No, at this time there is no plan to start a new loyalty program.' },
  { id: 0, clicked: false, category_slug: 'about-plaid-perks', name: 'What is Plaid Perks?', content: 'Plaid Perks™ is a first of its kind loyalty program.  It provides animal owners a single, unified platform across the country and across all species, that rewards them for all their Nutrena® purchases.' },
  { id: 1, clicked: false, category_slug: 'about-plaid-perks', name: 'How does it work?', content: 'Plaid Perks™ allows you to earn points for everything from buying Nutrena® brand products to answering survey questions. Use your points, which act like currency, to get exclusive rewards. No purchase necessary.' },
  // { id: 2, clicked: false, category_slug: 'about-plaid-perks', name: 'How do I start earning points?', content: 'Earn points by uploading receipts, participating in contests, reading blogs, referring friends and completing surveys and trivia! You will receive 10 points for every dollar you spend on Nutrena® brand products purchased (on approved receipts only.) You may earn up to 120,000 points per month.' },
  // { id: 3, clicked: false, category_slug: 'about-plaid-perks', name: 'Does it cost anything to join Plaid Perks™?', content: 'Joining Plaid Perks™ is completely free. To create an account, you will need to provide some basic personal information.' },
  { id: 4, clicked: false, category_slug: 'about-plaid-perks', name: 'Will new rewards and activities become available?', content: 'No new rewards or additional inventory will become available before the program ends on 9/5/2023.' },
  { id: 5, clicked: false, category_slug: 'about-plaid-perks', name: 'What are my Currrent Points?', content: 'Your “Current Points” are the number of points you have available to redeem rewards.' },
  { id: 6, clicked: false, category_slug: 'about-plaid-perks', name: 'Do Plaid Perks™ points expire?', content: 'Points earned as a member of Plaid Perks™ roll over from year to year as long as you are actively participating in the program. Points will expire after 18 months of no receipt upload. We will send reminder emails should you approach points expiration.' },
  // { id: 7, clicked: false, category_slug: 'about-plaid-perks', name: 'How is "Active Participation" in the program defined?', content: 'Active participation is defined as 1 receipt upload every 6 months. Active members qualify for more offers and unique rewards.' },
  { id: 8, clicked: false, category_slug: 'about-plaid-perks', name: 'Can I transfer my points or Plaid Perks™ rewards to another member?', content: 'Unfortunately you can’t transfer or sell points or rewards to other members of the program.' },
  { id: 9, clicked: false, category_slug: 'about-plaid-perks', name: 'What are the terms and conditions of the Plaid Perks™ program?', content: "The terms and conditions for the program can be found <a href='https://www.cargill.com/page/website-terms' target='_blank'>here.</a>" },
  // {
  //   id: 10,
  //   clicked: false,
  //   category_slug: 'about-plaid-perks',
  //   name: 'Who is eligible to join Plaid Perks?',
  //   content: 'Plaid Perks™ is limited to U.S. residents.  There are no additional restriction for who may join.  To create an account, you need to have a valid email address and to define an account owner.  You can use your personal email or an email address of a club, business, etc.  There are some promotions on the website that require members to be at least 18 years of age to participate.',
  // },
  { id: 11, clicked: false, category_slug: 'about-plaid-perks', name: 'How does Plaid Perks™ use my personal information?', content: 'Plaid Perks™ processes your personal information in accordance with Cargill’s Online Privacy Policy.' },

  // Account Information
  { id: 51, clicked: false, category_slug: 'account-information', name: 'Can I still join Plaid Perks?', content: 'New member registration is no longer available, as of August 7, 2023.' },

  // {
  //   id: 12,
  //   clicked: false,
  //   category_slug: 'account-information',
  //   name: 'How do I sign up for Plaid Perks?',
  //   content: 'Go to plaidperks.com and click the link to "Sign up now".  To create your account, you all need to provide your email address, first and last name, mailing address, birthday, animal ownership details, and you all need to create a password.  After creating your account, you all be able to start earning points by participating in activities on the site and uploading your Nutrena® receipts!',
  // },
  { id: 13, clicked: false, category_slug: 'account-information', name: "What to do if I'm having trouble participating in the program?", content: 'Please click <a href="https://www.plaidperks.com/faqs/#ad-blockers" target="_blank">here</a> to contact our Plaid Perks™ customer inquiry team.  Tell us about your issue and then one of our Support Specialists will reach out to assist you.' },
  { id: 14, clicked: false, category_slug: 'account-information', name: 'Where can I see my earned points and account information?', content: 'You can see all of your account information and account activity by logging into your Plaid Perks™ account and then clicking "Account" in the top right-hand corner of the page.  You can also click on "Check your receipt upload history" to see your uploaded receipts.' },

  // Uploading Receipts
  {
    id: 55,
    clicked: false,
    category_slug: 'uploading-receipts',
    name: "What if my most recent receipt(s) wasn't processed accurately?",
    content: 'Members have until August 25, 2023 to submit an inquiry requesting a manual review and adjustment of your receipt(s).',
  },
  { id: 52, clicked: false, category_slug: 'uploading-receipts', name: 'Until when can I submit receipts?', content: 'Members have until August 21, 2023 to submit any valid Nutrena® receipts for points.' },
  // {
  //   id: 15,
  //   clicked: false,
  //   category_slug: 'uploading-receipts',
  //   name: 'How do I submit receipts?',
  //   content: '<p>To claim the points from your purchase you can upload your physical receipt or forward it by email.</p> To upload Plaid Perks™ receipts, please follow these steps: <ol><li>Log into your account at plaidperks.com, if you do not have an account complete the online Registration form</li> <li>Click on "Receipt Upload" in the navigation bar</li><li>Follow the prompts that pop up by selecting the image of your receipt and clicking Upload</li></ol>',
  // },
  // { id: 16, clicked: false, category_slug: 'uploading-receipts', name: 'How does the receipt upload process work?', content: 'Upload a photo of your receipt to earn points for your Nutrena® purchases. The following information must be clearly visible in order to process your receipt: <ul><li>Store name</li><li>Purchase Date and Time</li><li>Nutrena® Product Name or SKU</li><li>Nutrena® Product Price</li><li>Nutrena® Product Quantity</li><li>Purchase Total</li></ul>' },
  // { id: 17, clicked: false, category_slug: 'uploading-receipts', name: 'Do I have to upload my receipts within a set timeframe?', content: 'Yes.  You have 6 months from the date of purchase to upload your receipt.  If the purchase date is more than 6 months ago, your receipt will not qualify for points.' },
  // {
  //   id: 18,
  //   clicked: false,
  //   category_slug: 'uploading-receipts',
  //   name: 'What should I do if my receipt won’t upload?',
  //   content:
  //     '<p>If your receipt does not upload:</p> <ul><li>Ensure you have waited at least 24 hours, then refresh your Activity History on “Your Account” page.</li> <li>Try taking a new picture. Ensure the receipt is not skewed or crumpled. If there is anything in the background of the photo the submission may not process correctly.</li><li>If your receipt is a hand-written receipt, please allow up to 2 full business days for processing.</li> <li>If your receipt still does not upload, contact Nutrena® at https://www.nutrenaworld.com/plaid-perks-inquiry.</li></ul>',
  // },
  // { id: 19, clicked: false, category_slug: 'uploading-receipts', name: 'My feed store only offers hand-written receipts. Can I still participate?', content: 'Yes, hand-written receipts are accepted in the Plaid Perks™ program. Please note that these receipts have a longer validation process, and you may experience a delay in points being added to your account, up to 2 full business days.' },
  // { id: 20, clicked: false, category_slug: 'uploading-receipts', name: 'Are any purchases excluded from the program?', content: 'Bulk (non-bagged) Nutrena® feed purchases are not eligible. Only bagged product purchases will be awarded points. Offer valid for US residents only.' },
  // { id: 21, clicked: false, category_slug: 'uploading-receipts', name: 'How many receipts can I upload?', content: 'You can upload an unlimited amount of receipts, but they must be from unique purchases. Receipts that have already been scanned will not be accepted.' },
  // { id: 22, clicked: false, category_slug: 'uploading-receipts', name: 'How many points do I earn on purchases?', content: 'You will receive 10 points for every dollar you spend on Nutrena® brand products purchased (excluding sales tax and applicable shipping), up to a maximum of 120,000 points per calendar month. Purchase of bulk (non-bagged) Nutrena® brand products are excluded from the program.' },
  // { id: 23, clicked: false, category_slug: 'uploading-receipts', name: 'How do I know if my purchase qualifies for Plaid Perks™ points?', content: '<p>Every Nutrena® product for any animal qualifies for Plaid Perks™ points.  The purchase date on the receipt must be within the previous 6 months in order to qualify for points.</p> Bulk Nutrena® feed purchases do not qualify.' },
  // {
  //   id: 24,
  //   clicked: false,
  //   category_slug: 'uploading-receipts',
  //   name: "Why can't I submit a receipt in my Plaid Perks™ account?",
  //   content:
  //     'There are many different possibilities that may be causing this issue with the Plaid Perks™ website. Here are some possible solutions for this issue: <ol><li>If you are not using Google Chrome, please download and try using the Chrome browser. Chrome is the recommended web browser for accessing plaidperks.com</li> <li>Try clearing your browser cache and ensuring there are no ad-blockers running.</li> <li>Please make sure that your device software is fully up to date, and that the app software is the latest version.</li> <li>Please turn your device off and fully reboot.</li> <li>If none of the above solutions work, the issue may be related to the network.  You may be using network that has a VPN, firewall, or a low bandwidth.  Please try disabling any VPN or firewall and possibly using a different network.  If you are using phone data, connect to another Wi-Fi or reset the Wi-Fi you currently are on.</li></ol>',
  // },
  // {
  //   id: 25,
  //   clicked: false,
  //   category_slug: 'uploading-receipts',
  //   name: 'My receipt was written by hand. How long will it take to process?',
  //   content: 'Hand-written receipts can take up to three business days to complete.  They will also be initially rejected as part of the process, but then manually approved and processed. If you do not see these points applied to your account after three business days, please click <a href="https://www.nutrenaworld.com/plaid-perks-inquiry" target="_blank">here</a> to contact our Plaid Perks™ customer inquiry team.',
  // },

  // Completing Activities
  // { id: 26, clicked: false, category_slug: 'completing-activities', name: 'Why do my points not update immediately after completing an activity?', content: 'Your points have been added to your account, you may simply need to refresh your page. You can verify that your activity registered to your account by visiting the “Account” page and viewing your Activity History.' },
  { id: 27, clicked: false, category_slug: 'completing-activities', name: 'Why should I subscribe to Nutrena® brand emails?', content: 'Subscribers are the first to hear about new rewards, activities, and official announcements. Go to <a href="https://www.nutrenaworld.com/sign-up-for-special-offers" target="_blank">NutrenaWorld.com</a> to get signed up.' },
  { id: 28, clicked: false, category_slug: 'completing-activities', name: 'How do I unsubscribe from Nutrena® brand emails?', content: 'In order to unsubscribe from emails, click the “unsubscribe” link in an email from Nutrena®.' },
  { id: 29, clicked: false, category_slug: 'completing-activities', name: 'Where can I find current sweepstakes?', content: 'Any current sweepstakes or contest can be found on the “Contest” page.' },

  // Redeeming Rewards
  {
    id: 53,
    clicked: false,
    category_slug: 'redeeming-rewards',
    name: 'Until when can I redeem my points for rewards?',
    content: 'Members have until September 8, 2023 to redeem rewards at <a href="https://www.plaidperks.com/" target="_blank">plaidperks.com</a>. Quantities of merchandise are limited; no new inventory will be made available once a product runs out of stock.<br/>After September 8, 2023, members with remaining points balances will be able to redeem their points for Nutrena feed coupons.',
  },
  {
    id: 54,
    clicked: false,
    category_slug: 'redeeming-rewards',
    name: 'How do I redeem my points for rewards after September 8, 2023?',
    content: 'After September 8, 2023, members with remaining points balances will be able to redeem their points for a Nutrena® feed coupon at a conversion rate of $1 for 250 points by contacting consumer support at the link below on or before February 28, 2025. <br/>Please provide your name, Plaid Perks™ member email address and mailing address with your inquiry.',
  },

  { id: 30, clicked: false, category_slug: 'redeeming-rewards', name: 'How do I redeem my points for a reward?', content: 'Go to the “Rewards” page. Click the “Use Points” button for a reward you’d like to redeem. In the popup window that appears, follow the checkout instructions. You will receive a confirmation email when the transaction is complete.' },
  { id: 31, clicked: false, category_slug: 'redeeming-rewards', name: 'How many rewards can I redeem at once?', content: 'You can redeem as many rewards as you have points earned to cover. Some rewards will only be available in limited quantities.' },
  { id: 32, clicked: false, category_slug: 'redeeming-rewards', name: 'Can I return a reward I redeemed?', content: 'All redeemed reward transactions are final and may not be exchanged or returned. Please make sure you confirm your reward selections prior to checkout.' },
  {
    id: 33,
    clicked: false,
    category_slug: 'redeeming-rewards',
    name: 'How do I access my print-at-home coupon?',
    content: "Upon redeeming the reward, you'll receive an email with a link to your coupon. The coupon link will expire once you open the link and click print. We recommend that you only click on your coupon link when you're all set to print.  If you have issues, please contact our Plaid Perks™ customer inquiry team <a href='https://www.nutrenaworld.com/plaid-perks-inquiry' target='_blank'>here</a>.",
  },
  { id: 34, clicked: false, category_slug: 'redeeming-rewards', name: 'I have not received my Plaid Perks™ coupon in the mail.', content: 'Coupons may take up to 15 business days to arrive. If you have not received your coupon after 15 days, please contact Plaid Perks™ customer inquiry team <a href="https://www.nutrenaworld.com/plaid-perks-inquiry" target="_blank">here</a>.' },

  // Good-To-Know Details
  { id: 35, clicked: false, category_slug: 'good-to-know-details', name: 'How many points do I earn on purchases?', content: 'You will receive 10 points for every dollar you spend on Nutrena® brand products purchased (excluding sales tax and applicable shipping), up to a maximum of 120,000 points per calendar month. Purchase of bulk (non-bagged) Nutrena® brand products are excluded from the program.' },
  // { id: 36, clicked: false, category_slug: 'good-to-know-details', name: 'Can I earn points for all Plaid Perks™ activities on my mobile or tablet browser?', content: 'You can earn points for most activities on a mobile device. You’ll need to disable private browsing on your mobile browser to access some activities.' },
  { id: 37, clicked: false, category_slug: 'good-to-know-details', name: 'Which browser is the most compatible with the rewards program?', content: 'All browsers work with the rewards program. Google Chrome is recommended.' },
  {
    id: 38,
    clicked: false,
    category_slug: 'good-to-know-details',
    name: 'Is there an app for my smartphone for Plaid Perks™?',
    content:
      '<p>Currently, there is not an app; however, the Plaid Perks™ website is mobile responsive, and you can pin it to the home screen of your phone or tablet:</p><p>On an Android, simply launch Chrome for Android and open the website or web page you want to pin to your home screen. Tap the menu button and tap “Add to home screen”. You’ll be able to enter a name for the shortcut and then Chrome will add it to your home screen.</p><p>On an iPhone, simply launch the Safari browser on Apple’s iOS and navigate to the website or web page you want to add to your home screen. Tap the Share button on the browser’s toolbar — that’s the rectangle with an arrow pointing upward. Tap the “Add to Home Screen” icon in the Share menu. You’ll be prompted to name the shortcut before tapping the final “Add” button.</p>',
  },
  // { id: 39, clicked: false, category_slug: 'good-to-know-details', name: 'Can I be part of Plaid Perks™ if I do not live in the United States or Canada?', content: 'Currently, only U.S. residents are eligible for Plaid Perks™.' },
  {
    id: 40,
    clicked: false,
    category_slug: 'good-to-know-details',
    name: 'How do you handle “cheating” or members trying to “game” the program?',
    content: 'Progress is closely monitored and suspicious activity is easily detected; cheating or gaming will not be tolerated. Beating the system by creating a workaround, writing a script or creating multiple accounts to earn points for friend referrals is not permitted. In order to ensure a fun and fair experience for everyone, any accounts suspected of cheating will lead to forfeiture of acquired points and possible suspension or termination of the account.',
  },
  { id: 41, clicked: false, category_slug: 'good-to-know-details', name: 'Whom can I contact with further questions about the Plaid Perks™ program or to exit the Plaid Perks™ program?', content: 'Contact Nutrena® <a href="https://www.nutrenaworld.com/plaid-perks-inquiry" target="_blank"><u>here</u></a>.' },
  {
    id: 42,
    clicked: false,
    category_slug: 'good-to-know-details',
    name: 'What ways can a rescue or non-profit organization use Plaid Perks™ Rewards?',
    content:
      'There are a few different ways in which rescue and non-profit organizations can leverage Plaid Perks™ Rewards: <ol><li>Rescue and non-profit organizations can setup their own Plaid Perks™ account and upload receipts to receive reward points.</li><li>An individual can leverage their Plaid Perks™ account to redeem rewards and then donate the coupons to the rescue or non-profit organization.</li><li>Lastly, an individual can send their receipts to be uploaded to Plaid Perks™ by the rescue or non-profit organization.</li></ol>',
  },
  // { id: 43, clicked: false, category_slug: 'good-to-know-details', name: 'I have 10 proof of purchases and the store will no longer take them.', content: 'The Plaid Perks™ program has now replaced the Buy 10 Get 1 UPC program.  Please consider signing up for an account and continue saving by visiting <a href="https://www.plaidperks.com/" target="_blank">PlaidPerks.com</a>.' },
  // { id: 44, clicked: false, category_slug: 'good-to-know-details', name: 'Where can I find information about your breeder program?', content: 'You can find more information about our Loyall® Pet Food Breeder Program by clicking <a href="http://www.nutrenaworld.com/loyall-pet-food-breeder-program" target="_blank">here</a>.' },
  // {
  //   id: 45,
  //   clicked: false,
  //   category_slug: 'good-to-know-details',
  //   name: 'Is this program replacing the Buy 10 Get 1 frequent buyer program on SafeChoice® and Loyall® products?',
  //   content:
  //     "Yes, the new Plaid Perks™ program has replaced the coupons in the monthly emails. In the new program, you have the opportunity to earn more coupons than what the email program was offering - we've got $5, $10, $20, and $50 coupons available in the program, and there's no limit to the number of coupons you can earn in a year, so you can earn based on how much you buy! Also, the coupons available in the program are good towards any Nutrena® product, so you won't be limited to just horse or pet products anymore.",
  // },
  {
    id: 46,
    clicked: false,
    category_slug: 'good-to-know-details',
    name: 'How can I use Plaid Perks™ without access to a phone and computer?',
    content:
      'The Plaid Perks™ program requires a device that can access the internet as well as scan or take photos of receipts.  You can use a computer, but you can also use a phone with a camera that has the capability of opening a web browser and connecting to PlaidPerks.com.  Plaid Perks™ is the only Nutrena® Loyalty program offered at this time. If you do not have a device that can access the internet, we encourage you to ask friends or family for assistance in using one of their devices or internet access.',
  },
  { id: 47, clicked: false, category_slug: 'good-to-know-details', name: 'My feed store does not accept the coupon that you sent me as a Plaid Perks™ reward.  What can I do to get my discount?', content: 'If your feed store will not accept your Nutrena® feed coupon, please contact Plaid Perks™ customer inquiry team here.  Please share with us the name of the store and the city and state in which the store is located.  We will contact the store to help get the issue resolved.' },
  // { id: 48, clicked: false, category_slug: 'good-to-know-details', name: 'What programs did Plaid Perks™ replace?', content: 'The following programs were eliminated before or at the same time Plaid Perks™ was launched: <ul><li>Loyall Frequent Buyer Program</li><li>SafeChoice Buy 10 Get 1</li><li>SafeChoice Buy 12 Get 1</li><li>Monthly Consumer Newletter $2 Coupons</li><li>Northeast Club Support Program</li><li>SHOW Program</li><li>Clubmaster (eliminated in 2019)</li></ul>' },
];
