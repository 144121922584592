import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
// eslint-disable-next-line
import { signup, getLockupAction, preRegistrationAction } from '../../redux/actions';
import { connect } from 'react-redux';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tooltip, OverlayTrigger, Button } from 'react-bootstrap';
import { isValid, differenceInYears } from 'date-fns';
import GooglePlaces from '../../components/GooglePlaces';
//import Calendar from "react-calendar";
import Required from '../../components/Required';
import Captcha from '../../components/CaptchaClick';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import InputMask from 'react-input-mask';
var validator = require('validator');

const formatedDateStr = value => {
  let d = value.split('/');
  return d[0] + '/15/' + d[1];
};
const Register = ({ signup, setLoading, bonusPointInfo }) => {
  const { t } = useTranslation();
  const confige = useSelector(state => state.app.config);
  const history = useHistory();
  const [place, setPlace] = useState({});
  const [showThanks, setShowThanks] = useState(false);
  const query = new URLSearchParams(window.location.search);
  const referralid = query.get('refCode') ? query.get('refCode') : '';
  const source = query.get('src') ? query.get('src') : '';
  const promoCode = query.get('promoCode') ? query.get('promoCode') : '';
  let [captchaValue, setCaptchaValue] = useState(false);
  let [captchaEnable, setCaptchaEnable] = useState(false);
  let [animalList, setAnimalList] = useState([]);
  let [countryList, setCountryList] = useState([]);
  let [stateList, setStateList] = useState([]);
  let [passwordViewOpt, setPasswordViewOpt] = useState({ password: false, confirmPassword: false });

  /*[START:INITIALIZE_DECLARATION]*/
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    confirmEmail: '',
    username: '',
    birth: '',
    homePhone: '',
    cellPhone: '',
    address: '',
    address2: '',
    country: '',
    state: '',
    suite: '',
    city: '',
    zip: '',
    gender: '',
    receiveEmail: true,
    receiveSms: true,
    companyName: '',
    shippingInstructions: '',
    password: '',
    passwordConfirm: '',
    instagramHandle: '',
    facebookHandle: '',
    tiktokHandle: '',
    twitterHandle: '',
    socialSecurityNumber: '',
    latitude: 0,
    longitude: 0,
    secondaryEmail: '',
    additionalInfo: { activity_tags_list: '', terms: false, visitorId: '', requestId: '', locationType: '', occupation: '', birthNotDisclose: false },
  });
  /*[END:INITIALIZE_DECLARATION]*/
  const [validation, setValidation] = useState({});
  const [error, setError] = useState({
    firstName: '',
    email: '',
    confirmEmail: '',
    homePhone: '',
    cellPhone: '',
    lastName: '',
    username: '',
    address: '',
    address2: '',
    country: '',
    state: '',
    suite: '',
    zip: '',
    city: '',
    shippingInstructions: '',
    receiveEmail: '',
    receiveSms: '',
    gender: '',
    passwordConfirm: '',
    password: '',
    instagramHandle: '',
    facebookHandle: '',
    tiktokHandle: '',
    twitterHandle: '',
    socialSecurityNumber: '',
    secondaryEmail: '',
    additionalInfo: { activity_tags_list: '', terms: '' },
  });
  useEffect(() => {
    toast.error('Plaid Perks is being discontinued from 07 Aug 2023. User registration is currently not allowed.');
    history.push('/');
    loadAnimalList();
  }, []);
  const loadAnimalList = () => {
    let requestData = { lookups: ['ACTIVITY_TAGS_LIST', 'STATE_LIST', 'COUNTRY_LIST'] };
    setLoading(true);
    getLockupAction(requestData)
      .then(response => {
        if (response.httpCode === 200 && response.data) {
          if (response.data.content) {
            let animalListInd = response.data.content.findIndex(v => v.name === 'ACTIVITY_TAGS_LIST');
            let comList = [];
            if (animalListInd >= 0) {
              comList = response.data.content[animalListInd].data;
              let dt = response.data.content[animalListInd].data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
              setAnimalList(dt);
            }
            let stListInd = response.data.content.findIndex(v => v.name === 'STATE_LIST');
            if (stListInd >= 0) {
              setStateList(response.data.content[stListInd].data);
            }
            let cnListInd = response.data.content.findIndex(v => v.name === 'COUNTRY_LIST');
            if (cnListInd >= 0) {
              setCountryList(response.data.content[cnListInd].data);
            }
          }
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
      });
  };
  useEffect(() => {
    let items = {};
    let oldState = { ...data, password: '', passwordConfirm: '' };
    setData(oldState);
    if (confige.uiConfig) {
      confige.uiConfig.userFields.forEach(element => {
        if (element.name === 'email') {
          items['confirmEmail'] = element.validation;
          // items['secondaryEmail'] = element.validation;
        }
        if (['birth'].indexOf(element.name) >= 0) {
          element.validation.mandatory = true;
        }
        items[element.name] = element.validation;
      });
      items.password = confige.uiConfig.passwordRule;
      items.passwordConfirm = confige.uiConfig.passwordRule;
      if (confige.fingerprinting) {
        if (confige.fingerprinting.enable) {
          const fpPromise = FingerprintJS.load({ apiKey: confige.fingerprinting.clientKey });
          // Get the visitor identifier when you need it.
          fpPromise
            .then(fp => fp.get())
            .then(result => {
              let fdata = JSON.parse(JSON.stringify(data));
              fdata.additionalInfo.visitorId = result.visitorId;
              fdata.additionalInfo.requestId = result.requestId;
              setData(fdata);
            });
        }
      }
    }
    if (confige.captchaConfig) {
      if (confige.captchaConfig.registrationCaptcha) {
        setCaptchaEnable(true);
      }
    }
    setValidation(items);
    // eslint-disable-next-line
  }, [confige, confige.uiConfig]);

  const handleChange = e => {
    const { name, value } = e.target;
    let errorMessage = '';
    let validationArray = validation[name];
    let check = false;
    for (let key in validationArray) {
      if (key === 'minLength') {
        if (value.length < validationArray[key] && value.length !== 0) {
          errorMessage = `${t('Should not be less then', { count: validationArray[key] })}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'maxLength') {
        if (value.length > validationArray[key]) {
          errorMessage = `${t('Should not be greater then', { count: validationArray[key] })}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'mandatory' && validationArray[key]) {
        if (!value.length) {
          errorMessage = `${t('Should not be empty')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'hasNumeric' && validationArray[key] && value.search(/[0-9]/) < 0) {
        errorMessage = `${t('Should be contain number')}.`;
        setError({ ...error, [name]: errorMessage });
        check = true;
      }
      if (key === 'hasAlpha' && validationArray[key] && value.search(/[A-Z]/) < 0 && value.search(/[a-z]/) < 0) {
        errorMessage = `${t('Should be contain alphabets')}.`;
        setError({ ...error, [name]: errorMessage });
        check = true;
      }
      if (key === 'email' && validationArray[key] && value) {
        if (!validator.isEmail(value)) {
          errorMessage = `${t('Please enter a valid email')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'upperCaseReq' && validationArray[key]) {
        if (value.search(/[A-Z]/) < 0 && value.length !== 0) {
          errorMessage = `${t('Should have at least one Upper Case latter')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'lowerCaseReq' && validationArray[key]) {
        if (value.search(/[a-z]/) < 0 && value.length !== 0) {
          errorMessage = `${t('Should have at least one Lower Case latter')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'specialCharReq' && validationArray[key]) {
        // eslint-disable-next-line
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (!format.test(value) && value.length !== 0) {
          errorMessage = `${t('Should have at least one special letter')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }

      if (name === 'passwordConfirm') {
        if (data.password !== value) {
          setError({ ...error, passwordConfirm: `${t('Password and confirm password should be same')}.` });
        }
      }
    }
    if (name === 'terms') {
      if (!e.target.checked) {
        setError({ ...error, additionalInfo: { ...error.additionalInfo, terms: `${t('Please select the above')}.` } });
      } else {
        setError({ ...error, additionalInfo: { ...error.additionalInfo, terms: '' } });
      }
      check = true;
    }

    if ((name === 'firstName' || name === 'lastName') && value.length !== 0) {
      const nameFormat = /^[a-zA-z]+([\s|'|’|-][a-zA-Z]+)*$/;
      const nameString = value.trim();
      if (!nameFormat.test(nameString)) {
        errorMessage = `Should not have any specific character (except ' or - between 2 character) and more then single space.`;
        setError({ ...error, [name]: errorMessage });
        check = true;
      }
    }
    if (name === 'birth' && value.length !== 0) {
      const dob = formatedDateStr(value);
      if (!isValid(new Date(dob)) || differenceInYears(new Date(), new Date(dob)) < 1) {
        errorMessage = `${t('Invalid')} ${t('Birth Date')}.`;
        setError({ ...error, [name]: errorMessage });
        check = true;
      }
    }
    if (!check) {
      setError({ ...error, [name]: '' });
    }
    let newAdditionalInfo = { ...data.additionalInfo };
    if (name === 'terms' || name === 'birthNotDisclose') {
      newAdditionalInfo[name] = e.target.checked;
    }
    let newData = { ...data };
    if (name === 'birthNotDisclose' && e.target.checked) {
      newData.birth = '';
      setError({ ...error, birth: undefined });
    }
    setData({ ...newData, [name]: value, additionalInfo: { ...newAdditionalInfo } });
  };

  const onSubmit = async () => {
    let errorArray = {};
    for (let name in data) {
      let value = data[name];
      let validationArray = validation[name];
      let errorMessage = '';
      if ((name === 'firstName' || name === 'lastName') && value.length !== 0) {
        const nameFormat = /^[a-zA-z]+([\s|'|’|-][a-zA-Z]+)*$/;
        const nameString = value.trim();
        if (!nameFormat.test(nameString)) {
          errorMessage = `Should not have any specific character (except ' or - between 2 character) and more then single space.`;
        }
      }
      if (name === 'birth' && value.length !== 0) {
        const dob = formatedDateStr(value);
        if (!isValid(new Date(dob)) || differenceInYears(new Date(), new Date(dob)) < 1) {
          errorMessage = `${t('Invalid')} ${t('Birth Date')}.`;
        }
      }
      for (let key in validationArray) {
        if (key === 'email' && validationArray[key] && !validator.isEmail(value) && value) {
          errorMessage = `${t('Please enter a valid email')}.`;
        } else if (key === 'minLength' && value.length < validationArray[key] && value.length !== 0) {
          errorMessage = `${t('Should not be less then', { count: validationArray[key] })}.`;
        } else if (key === 'maxLength' && value.length > validationArray[key]) {
          errorMessage = `${t('Should not be greater then', { count: validationArray[key] })}.`;
        } else if (key === 'mandatory' && validationArray[key] && !value.length) {
          if (name !== 'birth') {
            errorMessage = 'Should not be empty.';
          } else if (name === 'birth' && !data.additionalInfo.birthNotDisclose) {
            errorMessage = `${t('Should not be empty.')}`;
          }
        } else if (key === 'upperCaseReq' && value.search(/[A-Z]/) < 0 && value.length !== 0) {
          errorMessage = `${t('Should have at least one Upper Case latter')}.`;
        } else if (key === 'lowerCaseReq' && value.search(/[a-z]/) < 0 && value.length !== 0) {
          errorMessage = `${t('Should have at least one Lower Case latter')}.`;
        } else if (key === 'hasNumeric' && validationArray[key] && value.search(/[0-9]/) < 0) {
          errorMessage = `${t('Should be contain number')}.`;
        } else if (key === 'hasAlpha' && validationArray[key] && value.search(/[A-Z]/) < 0 && value.search(/[a-z]/) < 0) {
          errorMessage = `${t('Should be contain alphabets')}.`;
        } else if (key === 'specialCharReq' && value.length !== 0) {
          // eslint-disable-next-line
          var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
          if (!format.test(value)) {
            errorMessage = `${t('Should have at least one special letter')}.`;
          }
        }
      }
      if (name === 'additionalInfo') {
        let additionalInfoError = { activity_tags_list: '', terms: '' };
        if (data['additionalInfo']['activity_tags_list'].length === 0) {
          additionalInfoError.activity_tags_list = `${t('Please select animals.')}`;
        }
        if (!data['additionalInfo']['terms']) {
          additionalInfoError.terms = `${t('Please select the above.')}`;
        }
        errorArray[name] = { ...additionalInfoError };
      } else {
        errorArray[name] = errorMessage;
      }

      if (name === 'password' || name === 'passwordConfirm') {
        if (data['password'] !== data['passwordConfirm']) {
          errorMessage = `${t('Password and confirm password should be same')}.`;
        }
        errorArray['passwordConfirm'] = errorMessage;
      }
      if (name === 'confirmEmail') {
        if (data['email'] !== data['confirmEmail']) {
          errorMessage = `${t('Email and confirm email should be same.')}`;
        }
        errorArray['confirmEmail'] = errorMessage;
      }
    }
    setError(errorArray);
    let check = false;
    for (let key in errorArray) {
      if (errorArray[key] && key !== 'additionalInfo') {
        check = true;
      }
    }
    if (errorArray['additionalInfo']['activity_tags_list'] || errorArray['additionalInfo']['terms']) {
      check = true;
    }

    if (data.secondaryEmail !== '' && data.secondaryEmail) {
      if (!validator.isEmail(data.secondaryEmail)) {
        let errorMessage = `${t('Please enter a valid email')}.`;
        setError({ ...error, secondaryEmail: errorMessage });
        check = true;
      }
      if (data.secondaryEmail === data.email) {
        let errorMessage = `${t('Secondary email should not be the same as Primary email')}.`;
        setError({ ...error, secondaryEmail: errorMessage });
        check = true;
      }
    }
    if (!captchaValue && captchaEnable) {
      toast.error(`${t('Error')}! ${t('Please select captcha')}.`);
      return false;
    }
    const newData = { ...data };
    if (captchaEnable) {
      newData['captchaVal'] = captchaValue;
    }
    delete newData.passwordConfirm;
    if (errorArray.country && !errorArray.address) {
      toast.error(`${t('Country is not selected')}.`);
    } else if (errorArray.state && !errorArray.address) {
      toast.error(`${t('State is not selected')}.`);
    } else if (errorArray.zip && !errorArray.address) {
      toast.error(`${t('Zipcode is not selected')}.`);
    } else if (errorArray.city && !errorArray.address) {
      toast.error(`${t('City is not selected')}.`);
    }
    if (!check) {
      if (newData.birth) {
        let datevalue = new Date(formatedDateStr(newData.birth));
        const dd = String(datevalue.getDate()).padStart(2, '0');
        const mm = String(datevalue.getMonth() + 1).padStart(2, '0');
        const yyyy = datevalue.getFullYear();
        const formatedDate = yyyy + '-' + mm + '-' + dd;
        newData.birth = formatedDate;
      }
      if (!newData.hasOwnProperty('additionalInfo')) {
        newData['additionalInfo'] = {};
      }
      if (promoCode) {
        newData['additionalInfo']['promoCode'] = promoCode;
      }
      if (source) {
        newData['additionalInfo']['source'] = source;
      }
      setLoading(true);
      try {
        let signupCall = true;
        if (confige.usePreRegistration) {
          signupCall = false;
          let preRegistrationResp = await preRegistrationAction(newData);
          if (preRegistrationResp.data) {
            if (preRegistrationResp.data.valid) {
              signupCall = true;
              newData.additionalInfo.token = preRegistrationResp.data.token;
            } else {
              setLoading(false);
              toast.error(preRegistrationResp.data.errorReason);
            }
          } else {
            setLoading(false);
            toast.error(`${t('Something went wrong')}.`);
          }
        }
        if (signupCall) {
          let signupResp = await signup(newData, referralid, 1);
          toast.success(signupResp);
          window.scrollTo(0, 0);
          // history.push('/login');
          setShowThanks(true);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (typeof error === 'object') {
          error.forEach(item => {
            toast.error(`Something went wrong! ${item.message}`);
          });
        } else {
          toast.error(`${t('Something went wrong')}.`);
        }
      }
    }
  };

  const handleDate = value => {
    setData({ ...data, birth: value });
  };

  const handleAnimalList = event => {
    let values = data.additionalInfo.activity_tags_list.length > 0 ? data.additionalInfo.activity_tags_list.split(',') : [];
    values = event.target.checked ? [...values, event.target.value] : values.filter(item => item !== event.target.value);
    if (values.length === 0) {
      setError({ ...error, additionalInfo: { ...error.additionalInfo, activity_tags_list: `${t('Please select animals')}.` } });
    } else {
      setError({ ...error, additionalInfo: { ...error.additionalInfo, activity_tags_list: '' } });
    }
    setData({ ...data, additionalInfo: { ...data.additionalInfo, activity_tags_list: values.join(',') } });
  };
  useEffect(() => {
    if (place.hasOwnProperty('address')) {
      let err = { ...error };
      let curStateData = Object.assign({}, data);
      if (curStateData.hasOwnProperty('address')) {
        curStateData.address = place.address;
        err.address = '';
      }
      if (curStateData.hasOwnProperty('country')) {
        curStateData.country = place.country;
      }
      if (curStateData.hasOwnProperty('state')) {
        curStateData.state = place.state;
        err.state = '';
      }
      if (curStateData.hasOwnProperty('city')) {
        curStateData.city = place.city;
        err.city = '';
      }
      // if (curStateData.hasOwnProperty('suite')) {
      //   curStateData.suite = place.street;
      //   err.suite = '';
      // }
      if (curStateData.hasOwnProperty('zip')) {
        curStateData.zip = place.zip;
        err.zip = '';
      }
      if (curStateData.hasOwnProperty('latitude')) {
        curStateData.latitude = place.latitude;
      }
      if (curStateData.hasOwnProperty('longitude')) {
        curStateData.longitude = place.longitude;
      }
      setData(curStateData);
      setError(err);
    }
    // eslint-disable-next-line
  }, [place]);
  const googleSelectedAddressHandler = place => {
    setPlace(place);
  };
  const formatChars = {
    '1': '[12]',
    '0': '[0123456789]',
  };

  const ssnTooltip = <Tooltip id="tooltip">PLEASE TYPE IN YOUR ADDRESS AND SELECT IT FROM THE DROP-DOWN. IF YOU LIVE IN AN APARTMENT OR TOWNHOME, PLEASE ENTER THAT UNDER ‘APT/UNIT NUMBER'. IF NOT, PLEASE LEAVE THAT BLANK.</Tooltip>;

  return (
    <div className="page">
      <div className="container">
        <div className="page-section">
          {!showThanks && (
            <form role="form">
              <h1 className="main-heading mb-1">{t('Register Now')}</h1>
              <p className="text-center mb-5 mt-3">
                Already registered?{' '}
                <Link className="span-text-link" to={'/login'}>
                  Click here
                </Link>{' '}
                to login
              </p>
              <div className="row">
                {/*[START:FIRSTNAME]*/}
                <div className="form-group col-md-6 theme-signup-firstname">
                  <label htmlFor="first-name" className="col-sm-12 col-form-label">
                    <Trans>lang_key_fname</Trans>
                    {validation['firstName'] && validation['firstName'].mandatory ? <Required /> : ''}
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      data-test="register-firstName"
                      name="firstName"
                      onChange={handleChange}
                      className="form-control"
                      id="first-name"
                      // placeholder={`${t("Enter ")}${t("lang_key_fname")}`}
                      aria-required="true"
                      aria-autocomplete="name"
                    />
                    <span className="input-error" role="alert">
                      {error.firstName}
                    </span>
                  </div>
                </div>
                {/*[END:FIRSTNAME]*/}

                {/*[START:LASTNAME]*/}
                <div className="form-group col-md-6 theme-signup-lastname">
                  <label htmlFor="last-name" className="col-sm-12 col-form-label">
                    <Trans>lang_key_lname</Trans>
                    {validation['lastName'] && validation['lastName'].mandatory ? <Required /> : ''}
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      data-test="register-lasttName"
                      name="lastName"
                      onChange={handleChange}
                      className="form-control"
                      id="last-name"
                      // placeholder={`${t("Enter ")}${t("lang_key_lname")}`}
                      aria-required="true"
                      aria-autocomplete="family name"
                    />
                    <span className="input-error" role="alert">
                      {error.lastName}
                    </span>
                  </div>
                </div>
                {/*[END:LASTNAME]*/}

                {/*[START:EMAIL]*/}
                <div className="form-group col-md-6 theme-signup-email">
                  <label htmlFor="email" className="col-sm-12 col-form-label">
                    <Trans>lang_key_email</Trans>
                    {validation['email'] && validation['email'].mandatory ? <Required /> : ''}
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="email"
                      data-test="register-email"
                      name="email"
                      onChange={handleChange}
                      className="form-control"
                      id="email"
                      // placeholder={`${t("Enter ")}${t("lang_key_email")}`}
                      aria-required="true"
                      aria-autocomplete="email"
                    />
                    <span className="input-error" role="alert">
                      {error.email}
                    </span>
                  </div>
                </div>
                {/*[END:EMAIL]*/}
                {/*[START:CONF EMAIL]*/}
                <div className="form-group col-md-6 theme-signup-conf-email">
                  <label htmlFor="confirmEmail" className="col-sm-12 col-form-label">
                    <Trans>CONFIRM EMAIL</Trans>
                    {validation['confirmEmail'] && validation['confirmEmail'].mandatory ? <Required /> : ''}
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="email"
                      data-test="register-conf-email"
                      name="confirmEmail"
                      onChange={handleChange}
                      className="form-control"
                      id="confirmEmail"
                      // placeholder={`${t("Enter ")}${t("confirm email.")}`}
                      aria-required="true"
                      aria-autocomplete="confirmEmail"
                    />
                    <span className="input-error" role="alert">
                      {error.confirmEmail}
                    </span>
                  </div>
                </div>
                {/*[END:CONF EMAIL]*/}
                {/*[START:SECONDARY EMAIL]*/}
                <div className="form-group col-md-6 theme-signup-conf-email">
                  <label htmlFor="confirmEmail" className="col-sm-12 col-form-label">
                    <Trans>SECONDARY EMAIL</Trans>
                    {validation['secondaryEmail'] && validation['secondaryEmail'].mandatory ? <Required /> : ''}
                  </label>
                  <div className="col-sm-12">
                    <input type="email" data-test="register-secondary-email" name="secondaryEmail" onChange={handleChange} className="form-control" id="secondaryEmail" aria-required="true" aria-autocomplete="secondaryEmail" />
                    <span className="input-error" role="alert">
                      {error.secondaryEmail}
                    </span>
                  </div>
                </div>
                {/*[END:SECONDARY EMAIL]*/}
                {/*[START:USERNAME]*/}
                <div className="form-group col-md-6 theme-signup-username">
                  <label htmlFor="user-name" className="col-sm-12 col-form-label">
                    <Trans>lang_key_username</Trans>
                    {validation['username'] && validation['username'].mandatory ? <Required /> : ''}
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      data-test="register-username"
                      name="username"
                      onChange={handleChange}
                      className="form-control"
                      id="user-name"
                      // placeholder={`${t("Enter ")}${t("lang_key_username")}`}
                      aria-required="true"
                      aria-autocomplete="user"
                    />
                    <span className="input-error" role="alert">
                      {error.username}
                    </span>
                  </div>
                </div>
                {/*[END:USERNAME]*/}
                {/*[START:PASSWORD]*/}
                <div className="form-group col-md-6 theme-signup-password">
                  <label htmlFor="password" className="col-sm-12 col-form-label">
                    <Trans>Password</Trans>
                    {validation['password'] && validation['password'].mandatory ? <Required /> : ''}
                  </label>
                  <div className="col-sm-12 input-group">
                    <input
                      type={!passwordViewOpt.password ? 'password' : 'text'}
                      data-test="register-password"
                      name="password"
                      onChange={handleChange}
                      className="form-control"
                      id="password"
                      // placeholder={`${t("Enter ")}${t("password")}`}
                      aria-required="true"
                      aria-describedby="Password-btn"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-light form-control pl-3"
                        type="button"
                        id="password-btn"
                        onClick={() => {
                          setPasswordViewOpt({ ...passwordViewOpt, password: !passwordViewOpt.password });
                        }}>
                        {!passwordViewOpt.password ? 'Show' : 'Hide'}

                        {/* <span className="material-symbols-outlined">{!passwordViewOpt.password ? 'visibility_off' : 'visibility'}</span> */}
                      </button>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <span className="input-error" role="alert">
                      {error.password}
                    </span>
                  </div>
                </div>
                <div className="form-group col-md-6 theme-signup-password">
                  <label htmlFor="password-confirm" className="col-sm-12 col-form-label">
                    <Trans>Confirm password</Trans>
                    {validation['passwordConfirm'] && validation['passwordConfirm'].mandatory ? <Required /> : ''}
                  </label>
                  <div className="col-sm-12 input-group">
                    <input
                      type={!passwordViewOpt.confirmPassword ? 'password' : 'text'}
                      data-test="register-password-confirm"
                      name="passwordConfirm"
                      onChange={handleChange}
                      className="form-control"
                      id="password-confirm"
                      // placeholder={t("confirm password")}
                      aria-required="true"
                      aria-describedby="confirm-password-btn"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-light form-control pl-3"
                        type="button"
                        id="confirm-password-btn"
                        onClick={() => {
                          setPasswordViewOpt({ ...passwordViewOpt, confirmPassword: !passwordViewOpt.confirmPassword });
                        }}>
                        {!passwordViewOpt.confirmPassword ? 'Show' : 'Hide'}
                        {/* <span className="material-symbols-outlined">{!passwordViewOpt.confirmPassword ? 'visibility_off' : 'visibility'}</span> */}
                      </button>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <span className="input-error" role="alert">
                      {error.passwordConfirm}
                    </span>
                  </div>
                </div>
                {/*[END:PASSWORD]*/}
                {/*[START:ADDRESS]*/}
                {/* <div className="form-group col-md-6 theme-signup-address-finder d-none">
                  <div className="custom-tooltip-wrap">
                    <label htmlFor="addressFinder" className="col col-form-label">
                      <Trans>lang_key_select_address</Trans>
                      {validation['address'] && validation['address'].mandatory ? <Required /> : ''}
                      <OverlayTrigger trigger={['hover', 'focus']} placement="auto" overlay={ssnTooltip}>
                        <button type="button" className="btn default">?</button>
                      </OverlayTrigger>
                    </label>
                  </div>
                  <div className="col-sm-12">
                    <GooglePlaces getPlaceHandler={googleSelectedAddressHandler} placeholder={t('lang_key_select_address')} />
                    <span className="input-error" role="alert">
                      {error.address}
                    </span>
                  </div>
                </div> */}
                {/*[START:ADDRESS]*/}
                <div className="form-group col-md-6 theme-signup-address">
                  <label htmlFor="address" className="col-sm-12 col-form-label custom-tooltip-wrap">
                    <Trans>lang_key_add_address</Trans>
                    {validation['address'] && validation['address'].mandatory ? <Required /> : ''}
                    {/* <OverlayTrigger trigger={['hover', 'focus']} placement="auto" overlay={ssnTooltip}>
                      <button type="button" className="btn default">?</button>
                    </OverlayTrigger> */}
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      data-test="register-address"
                      value={data.address}
                      name="address"
                      className="form-control"
                      id="address"
                      onChange={handleChange}
                      // placeholder={`${t("Enter ")}${t("lang_key_add_address")}`}
                      aria-required="true"
                      aria-autocomplete="address"
                    />
                    <span className="input-error" role="alert">
                      {error.address}
                    </span>
                  </div>
                </div>
                {/*[END:ADDRESS]*/}
                {/*[START:SUITE]*/}
                <div className="form-group col-md-6 theme-profile-suite">
                  <label htmlFor="suite" className="col-sm-12 col-form-label">
                    <Trans>Apt/Unit Number</Trans>
                    {validation['suite'] && validation['suite'].mandatory ? <Required /> : ''}
                  </label>
                  <div className="col-sm-12">
                    <input type="text" data-test="profile-suite" name="suite" onChange={handleChange} value={data.suite ? data.suite : ''} className="form-control" id="suite" placeholder={`${t('Enter ')}${t('Apt/Unit Number')}`} />
                    <span className="input-error" role="alert">
                      {error.suite}
                    </span>
                  </div>
                </div>
                {/*[END:SUITE]*/}
                {/*[START:CITY]*/}
                <div className="form-group col-md-6 theme-signup-city">
                  <label htmlFor="city" className="col-sm-12 col-form-label">
                    <Trans>City</Trans>
                    {validation['city'] && validation['city'].mandatory ? <Required /> : ''}
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      data-test="signup-city"
                      name="city"
                      value={data.city}
                      onChange={handleChange}
                      className="form-control"
                      id="city"
                      // placeholder={`${t("Enter ")}${t("City")}`}
                      aria-required="true"
                      aria-autocomplete={t('City')}
                    />
                    <span className="input-error" role="alert">
                      {error.city}
                    </span>
                  </div>
                </div>
                {/*[END:CITY]*/}

                {/*[START:STATE]*/}
                <div className="form-group col-md-6 theme-signup-state">
                  <label htmlFor="state" className="col-sm-12 col-form-label">
                    {t('lang_key_add_state')}
                    {validation['state'] && validation['state'].mandatory ? <Required /> : ''}
                  </label>
                  <div className="col-sm-12">
                    <select
                      data-test="register-state"
                      name="state"
                      value={data.state}
                      onChange={handleChange}
                      className="form-control"
                      id="state"
                      // placeholder={`${t("Enter ")}${t("lang_key_add_state")}`}
                      aria-required="true"
                      aria-autocomplete="state">
                      <option value="">
                        {t('Select')} {t('State')}
                      </option>
                      {stateList
                        .filter(v => ['AE', 'DC', 'FM', 'GU', 'PR', 'VI'].indexOf(v.id) < 0)
                        .map(v => (
                          <option value={v.id}>{v.name}</option>
                        ))}
                      <hr />
                      {stateList
                        .filter(v => ['AE', 'DC', 'FM', 'GU', 'PR', 'VI'].indexOf(v.id) >= 0)
                        .map(v => (
                          <option value={v.id}>{v.name}</option>
                        ))}
                    </select>
                    <span className="input-error" role="alert">
                      {error.state}
                    </span>
                  </div>
                </div>
                {/*[END:STATE]*/}

                {/*[START:ZIP]*/}
                <div className="form-group col-md-6 theme-signup-zip">
                  <label htmlFor="zip" className="col-sm-12 col-form-label">
                    <Trans>Zip</Trans>
                    {validation['zip'] && validation['zip'].mandatory ? <Required /> : ''}
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      data-test="signup-zip"
                      name="zip"
                      value={data.zip}
                      onChange={handleChange}
                      className="form-control"
                      id="zip"
                      // placeholder={`${t("Enter ")}${t("Zip")}`}
                      aria-required="true"
                      aria-autocomplete={t('Zip')}
                    />
                    <span className="input-error" role="alert">
                      {error.zip}
                    </span>
                  </div>
                </div>
                {/*[END:ZIP]*/}

                {/*[START:COUNTRY]*/}
                <div className="form-group col-md-6 theme-signup-country">
                  <label htmlFor="country" className="col-sm-12 col-form-label">
                    <Trans>lang_key_add_country</Trans>
                    {validation['country'] && validation['country'].mandatory ? <Required /> : ''}
                  </label>
                  <div className="col-sm-12">
                    <select
                      data-test="register-country"
                      name="country"
                      value={data.country}
                      onChange={handleChange}
                      className="form-control"
                      id="country"
                      // placeholder={`${t("Enter ")}${t("lang_key_add_country")}`}
                      aria-required="true"
                      aria-autocomplete="country">
                      <option value="">
                        {t('Select')} {t('Country')}
                      </option>
                      {countryList.map(v => (
                        <option value={v.id}>{v.name}</option>
                      ))}
                    </select>
                    <span className="input-error" role="alert">
                      {error.country}
                    </span>
                  </div>
                </div>
                {/*[END:COUNTRY]*/}

                {/*[START:BIRTH]*/}
                <div className="form-group col-md-6 theme-signup-birth">
                  <label htmlFor="birth" className="col-sm-12 col-form-label theme-signup-label-birth">
                    <Trans>Birth Date</Trans>
                    {validation['birth'] && validation['birth'].mandatory ? <Required /> : ''}
                  </label>
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-4">
                        <InputMask className="form-control show-placeholder" mask="00/1000" name="birth" maskChar={''} value={data.birth} id="birth" formatChars={formatChars} onChange={handleChange} placeholder="MM/YYYY" aria-required="true" aria-autocomplete="DOB" disabled={data.additionalInfo.birthNotDisclose}></InputMask>
                        <span className="input-error" role="alert">
                          {error.birth}
                        </span>
                      </div>
                      <div className="col-sm-6 mt-4 mt-sm-0">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={data.additionalInfo.birthNotDisclose}
                              onChange={handleChange}
                              name="birthNotDisclose"
                              color="primary"
                              value={data.additionalInfo.birthNotDisclose}
                              inputProps={{
                                'aria-label': `Prefer not to disclose`,
                              }}
                            />
                          }
                          label="Prefer not to disclose"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/*[END:BIRTH]*/}

                {/*[START:GENDER]*/}
                <div className="form-group col-md-6 theme-signup-gender">
                  <label htmlFor="gender" className="col-sm-12 col-form-label theme-signup-label-gender">
                    <Trans>Gender</Trans>
                    {validation['gender'] && validation['gender'].mandatory ? <Required /> : ''}
                  </label>
                  <div className="col-sm-12">
                    <select
                      className="form-control"
                      data-test="signup-gender"
                      name="gender"
                      onChange={handleChange}
                      id="gender"
                      // placeholder={t("Gender")}
                      aria-required="true">
                      <option value="">
                        {t('Select')} {t('Gender')}
                      </option>
                      <option value="m">{t('Male')}</option>
                      <option value="f">{t('Female')}</option>
                      <option value="na">{t('Prefer not to disclose')}</option>
                    </select>
                    <span className="input-error" role="alert">
                      {error.gender}
                    </span>
                  </div>
                </div>
                {/*[END:GENDER]*/}

                {/*[START:RECEIVEEMAIL]*/}
                {/* <div className="form-group col-md-6 theme-profile-receiveEmail">
                <label htmlFor="receiveEmail" className="col-sm-12 col-form-label theme-profile-label-receiveEmail">
                  <Trans>Opt-In</Trans>
                  {validation['receiveEmail'] && validation['receiveEmail'].mandatory ? <Required /> : ''}
                </label>
                <div className="col-sm-12">
                  <select className="form-control" data-test="profile-email" name="receiveEmail" onChange={handleChange} value={data.receiveEmail !== null ? data.receiveEmail : ''} id="receiveEmail" placeholder={t('Opt-In')}>
                    <option value="">{t('Select')}</option>
                    <option value={true}>{t('Yes')}</option>
                    <option value={false}>{t('No')}</option>
                  </select>
                  <span className="input-error" role="alert">
                    {error.receiveEmail}
                  </span>
                </div>
              </div> */}
                {/*[END:RECEIVEEMAIL]*/}
                {/*[START:ANIMAL-LIST]*/}
                <div className="form-group col-sm-12 mt-3 theme-signup-lastname">
                  <label htmlFor="animalList" className="col-sm-12 col-form-label theme-profile-label-animalList">
                    <Trans>Animal Preference</Trans>
                    <Required />
                  </label>
                  <div className="animal-list col">
                    {animalList.map(listItem => {
                      return <FormControlLabel key={listItem.id} control={<Checkbox checked={data.additionalInfo.activity_tags_list.includes(listItem.id)} onChange={handleAnimalList} name={listItem.id} color="primary" value={listItem.id} inputProps={{ 'aria-label': `Select ${listItem.name}` }} />} label={listItem.name} />;
                    })}
                    <div className="input-error" role="alert">
                      {error.additionalInfo?.activity_tags_list}
                    </div>
                  </div>
                </div>
                {/*[END:ANIMAL-LIST]*/}
                {/*[START:TERMS]*/}
                <div className="form-group col-md-12 theme-signup-lastname">
                  <label htmlFor="terms" className="col-sm-12 col-form-label theme-profile-label-terms">
                    <Trans>Agree to Terms</Trans>
                    <Required />
                  </label>
                  <div className="col-sm-12 term-lable">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.additionalInfo.terms}
                          onChange={handleChange}
                          name="terms"
                          color="primary"
                          value={data.additionalInfo.terms}
                          inputProps={{
                            'aria-label': `select terms and condition`,
                            id: 'termsAndCondition',
                          }}
                        />
                      }
                    />
                    <Typography variant="body1" align="left" component="span">
                      I agree to the
                      <a href="https://www.cargill.com/page/website-terms" target="_blank">
                        {' '}
                        Terms & Conditions
                      </a>{' '}
                      and
                      <a href="https://www.cargill.com/privacy" target="_blank">
                        {' '}
                        Privacy Policy.
                      </a>
                      <Required />
                    </Typography>

                    <Typography variant="body2" align="left" className="pt-3 pt-sm-0">
                      I understand that Plaid Perks may send me news and promotions. You can unsubscribe at any time via the bottom of any Plaid Perks email.
                    </Typography>
                    <div className="input-error" role="alert">
                      {error.additionalInfo.terms}
                    </div>
                  </div>
                </div>
                {/*[END:TERMS]*/}

                {/*[START:GOOGLECAPTCHA]*/}
                {captchaEnable ? (
                  <div className="form-group col-md-6">
                    <label htmlFor="g-recaptcha-response" className="col-sm-12 invisible" aria-hidden="true">
                      Google captcha
                    </label>
                    <div className="col-sm-12">
                      <Captcha reset={0} parentCallback={setCaptchaValue} />
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {/*[END:GOOGLECAPTCHA]*/}
                <div className="col-md-12 mt-5">
                  <button type="button" id="register-submit-btn" data-test="register-submit" onClick={onSubmit} className="btn btn-primary">
                    <Trans>Create Account</Trans>
                  </button>
                </div>
                {/* <div className="col-md-12 forgot-pass-links text-center mt-5">
								<a href="/login" id="login-href">
									<Trans>click here to login your account.</Trans>
								</a>
							</div> */}
              </div>
            </form>
          )}

          {/* verification page */}
          {showThanks && (
            <div>
              <div className="verification-page">
                <div className="row align-items-center">
                  <div className="col-12 align-items-center">
                    <div className="text-center">
                      <div className="">
                        <img src="/public/assets/images/plaidperks-logo.png" />
                      </div>
                      <h3 className="mt-5">THANKS FOR REGISTERING TO BECOME A PART OF OUR COMMUNITY! </h3>
                      <h3>
                        YOU’VE JUST EARNED <span>{bonusPointInfo}</span> POINTS TO CELEBRATE 102 YEARS OF NUTRENA
                      </h3>
                      <div className="f-size-24 mt-5">
                        <div className="">
                          <strong>Please check your email for the verification link</strong>
                        </div>
                        <p className="text-center">
                          Once Verified You Can{' '}
                          <a href="/login" className="green-text-link f-size-24">
                            <strong>Login</strong>
                          </a>
                        </p>
                      </div>
                      {/* <div className="mt-5 f-size-18">
                        <div className="">Didn’t get the email? Make sure to check your junk or spam folders</div>
                        <p className="text-center">
                          Still not there?{' '}
                          <a href="/login" className="green-text-link f-size-18">
                            <strong>Resend</strong>
                          </a>
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* END verification page */}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  const onRegistration = state?.app?.config?.bonusPointInfo?.onRegistration;
  return {
    bonusPointInfo: onRegistration ? onRegistration : 102,
  };
};
export default connect(mapStateToProps, { signup })(IsLoadingHOC(Register, 'Wait .....'));
