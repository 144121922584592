import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import EarnPoints from '../../components/earn-point/EarnPoints';
const EarnPointsPreview = props => {
  const history = useHistory();
  useEffect(() => {
    toast.error(`This webpage is not available.`);
    history.push('/');
  }, []);
  return (
    <>
      <EarnPoints />
    </>
  );
};

export default EarnPointsPreview;
