import React from 'react';
import { getManu } from '../../redux/actions';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagramSquare } from '@fortawesome/free-brands-svg-icons';
import CopyRight from '../../components/CopyRight';
//import { useTranslation } from "react-i18next";
const Footer = () => {
  //const { t } = useTranslation();
  return (
    <footer>
      <Container fluid className="footer">
        <div className="row">
          <div className="col-sm-12">
            <div className="social-links">
              <ul className="footer-social">
                <li>
                  <a href="https://www.facebook.com/nutrena/" target="_blank">
                    <FontAwesomeIcon icon={faFacebook} alt="connect on Facebook" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/nutrenafeed/" target="_blank">
                    <FontAwesomeIcon icon={faInstagramSquare} alt="connect on Instagram" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/NutrenaFeed" target="_blank">
                    <FontAwesomeIcon icon={faTwitter} alt="connect on Twitter" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="footer-link ml-auto" aria-label="Footer Navigation">
              <ul>
                <li>
                  <a href="/faq">FAQ</a>
                </li>
                <li>
                  <a href="https://www.nutrenaworld.com/plaid-perks-inquiry" target="_blank">
                    Support
                  </a>
                </li>
                {/* <li><a href="https://www.cargill.com/page/website-terms">Program Terms & Conditions</a></li> */}
                <li>
                  <a href="https://www.cargill.com/privacy" target="_blank">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="https://www.cargill.com/page/website-terms" target="_blank">
                    Terms of Use
                  </a>
                </li>
                <li id="teconsent">{/* <a href="#" id="teconsent">
                    Manage Cookies
                  </a> */}</li>
              </ul>
            </div>
          </div>
          <div className="col-sm-12 text-center">
            <CopyRight />
          </div>
        </div>
      </Container>
    </footer>
  );
};
export default connect(null, { getManu })(Footer);
