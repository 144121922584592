import React, { useEffect } from "react";
import { Container, Alert } from "react-bootstrap";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
const UpcomingCampaign = (props) => {
	const config = useSelector((state) => state.app.config);
	const history = useHistory();
	useEffect(() => {
		if (config.campaignState !== "coming") {
			history.push("/");
		}
	}, [config]);
	return (
		<>
			<div className="w-100 mb-sm-5">
				<div className="hero-banner">
					<div className="">
					<div className="top-banner">
						<img className="w-100 banner-img d-none d-sm-block" src="/public/assets/images/Web_Interim_Woah.jpg" alt='Upcoming' />
						<img className="w-100 banner-img d-block d-sm-none" src="/public/assets/images/Web_Interim_Woah-Mob.png" alt='Upcoming' />
						<div className="card-img-overlay upcoming">
							<div className="row">
								<div className="col-12 col-sm-6">
									<div className="px-sm-5">
										<h1 className='mt-sm-5 white px-sm-5'>Woah <br />there!</h1>
									</div>
								</div>
								<div className="col-12 col-sm-6 white">
									<div className="mt-sm-5 mt-4 px-sm-5">
										<h4 className="white text-left pr-sm-5 ">
											We’re sorry for the inconvenience. An improved Plaid Perks™ experience is under construction and will be ready to run February 1st. But don’t worry! Your points and account information won’t be affected, and you can upload recent receipts after the site is back up.
										</h4>
										<h3><strong>Questions? Check the section below.</strong></h3>
									</div>
								</div>
							</div>
						</div>
					</div>
					</div>
				</div>
			</div>
			<Container className="">
			<div className="page-section pt-0">
				<div className="row justify-content-center">
					<div className="col-sm-10">
						<h1 className="upcominng-faq-heading">FAQs</h1>
					</div>
				</div>
				<div className="row justify-content-center mt-4">
					<div className="col-sm-10">
						<div className="mb-3">
							<h4><strong>Will I lose my points?</strong></h4>
							<p>Nope! Points won’t be lost while we are improving the site.</p>
						</div>
						<div className="mb-3">
							<h4><strong>When is the site reopening?</strong></h4>
							<p>February 1st</p>
						</div>
						<div className="mb-3">
							<h4><strong>What should I do with my receipts of purchases in the meantime?</strong></h4>
							<p>Hang on to your receipts! Stop back on or after February 1st to submit them and earn your well-deserved points.</p>
						</div>
						<div className="mb-3">
							<h4><strong>Can I sign up while the site is down?</strong></h4>
							<p>Thanks for stopping by! We’re so happy you’re here and want to join our awesome program. Stop back on or after February 1st to make it official.</p>
						</div>
						<div className="mb-3">
							<h4><strong>Will any of my account information be impacted?</strong></h4>
							<p>Your account information will not be affected, and your most recent activity details will be carried over. However, you will need to reset your password and update some user profile details when you log in on or after February 1st.</p>
						</div>
					</div>
				</div>
				
				
			</div>
			
		</Container>
		</>
	);
};

export default UpcomingCampaign;
