import React from 'react';
import { useSelector } from 'react-redux';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import PortalPointList from '../../components/PortalPointList';
import RewardsList from '../../components/rewards/RewardsList';
import { Container } from 'react-bootstrap';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import CarouselCustom from '../../components/CarouselCustom';
import { Link } from 'react-router-dom';
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
function Home(props) {
  const user = useSelector(({ user }) => user);

  if (user.accessToken) {
    return (
      <>
        {/* To Banner : Start */}
        <div className="banner-wrapper">
          <div className="hero-banner home-logged-hero-banner">
            <Container>
              <div className="row">
                <div className="col-sm-7 white">
                  <h1 className="header-1">Plaid Perks™ is being discontinued.</h1>
                  <h4>
                    <strong>
                      We are no longer accepting new receipt submissions. You may use your available points to redeem rewards at PlaidPerks.com, while supplies last, until September 8, 2023. Click{' '}
                      <Link to="/faq" className="white-link">
                        here
                      </Link>{' '}
                      to learn more.
                    </strong>
                    {/* <strong>
                      Members have until August 21, 2023, to submit valid receipts or points codes to earn points and until September 8, 2023 to redeem their points for rewards at PlaidPerks.com. Click{' '}
                      <Link to="/faq" className="white-link">
                        here
                      </Link>{' '}
                      to learn more.
                    </strong> */}
                  </h4>
                  <p>Members will receive an email with instructions for how to redeem points after September 8, 2023.</p>
                </div>
              </div>
            </Container>
          </div>
        </div>
        {/* To Banner : End */}

        <div className="wahtsnew_section page-section">
          {/* What's New : Start */}
          <div className="mb-5">
            <PortalPointList pagesize="500" section="WhatsNew" page="home" />
          </div>
          {/* What's New : End */}
          {/* SECTION HEADER(earn points by) : Start */}
          {/* <div className="bg-light py-5 my-5">
            <div className="container earnpoint-twin">
              <div className="row">
                <div className="col-12">
                  <h2 className="">Earn Points</h2>
                </div>
                <div className="col-sm-6 goto_faq-section d-sm-flex d-block align-items-sm-start align-items-md-center">
                  <div className="row">
                    <div className="col-md-4 d-flex align-items-md-center">
                      <img src="/public/assets/images/icons/Nut-PPsite-icons_receipt.png" className="img-fluid" alt="receipt" />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h4 className="card-title">
                          Upload & Manage <br />
                          Receipts
                        </h4>
                        <a href="/upload-receipt" className="btn btn-primary">
                          Upload
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 start_earning_section d-sm-flex d-block align-items-sm-start align-items-md-center">
                  <div className="row">
                    <div className="col-md-4 d-flex align-items-md-center">
                      <img src="/public/assets/images/icons/Nut-PPsite-icons_redeem-code.png" className="img-fluid" alt="Redeem Code" />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h4 className="card-title">
                          Redeem a <br />
                          Code{' '}
                        </h4>
                        <a href="/validate-code" className="btn btn-primary">
                          Enter a Code
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* SECTION HEADER(earn points by) : End */}
          {/* SECTION HEADER(get new rewarded) : Start */}
          <div className="">
            <RewardsList pagesize="4" showCouponBlock={true} title="Featured Rewards" />
          </div>
          {/* SECTION HEADER(get new rewarded) : End */}

          {/* stories and news */}
          <div className="mb-5">
            <PortalPointList pagesize="500" section="PlaidStoriesNews" page="home" />
          </div>
          {/* end stories and news */}
        </div>
        {/* Bottom Banner : Start */}
        {/* <div className="w-100">
          <div className="bottom-banner">
            <div className="faq-wrapper refer-wrapper">
              <div className="container">
                <div className="row">
                  <div className="col-sm-7 col-md-5">
                    <div className="mt-5 pt-md-5">
                      <h2 className="text-left white">Earn points for referring a friend.</h2>
                      <a href="/earnpoints" className="btn btn-primary mt-4">
                        more info
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* Bottom Banner : End */}
      </>
    );
  } else {
    return (
      <>
        {/* <CarouselCustom />
			<PortalPointList pagesize={4} />
			<Contests pagesize={4} /> */}

        <div className="banner-wrapper">
          <div className="hero-banner home-hero-banner white">
            <Container>
              <div className="row ">
                <div className="col-sm-7 ">
                  <h1>Plaid Perks™ is being discontinued.</h1>
                  <h4>
                    <strong>
                      We are no longer accepting new receipt submissions. Members may use their available points to redeem rewards at PlaidPerks.com, while supplies last, until September 8, 2023. New member registration is no longer available, as of August 7, 2023. Click{' '}
                      <Link to="/faq" className="white-link">
                        here
                      </Link>{' '}
                      to learn more.
                    </strong>

                    {/* <strong>
                      Members have until August 21, 2023, to submit valid receipts or points codes to earn points and until September 8, 2023, to redeem their points for rewards at PlaidPerks.com. New member registration is no longer available, as of August 7, 2023. Click{' '}
                      <Link to="/faq" className="white-link">
                        here
                      </Link>{' '}
                      to learn more.
                    </strong> */}
                  </h4>
                  <p>Members will receive an email with instructions for how to redeem points after September 8, 2023.</p>
                  <div className="">
                    {/* <a href="/register" className="btn btn-primary mt-3 mt-sm-5 mr-3 ">
                      Sign Up
                    </a> */}
                    <a href="/login" className="btn btn-primary mt-3 mt-sm-5">
                      Log In
                    </a>
                  </div>
                </div>
              </div>
            </Container>
          </div>
          {/* <CarouselCustom /> */}
        </div>
        <div className="benefits_section page-section">
          <Container>
            <h2>Benefits of Plaid Perks</h2>
            <div className="row d-flex">
              <div className="col-sm-6 col-md-3">
                <div className="text-center">
                  <div className="px-3 mt-5 mb-4">
                    <img className="w-100" src="/public/assets/images/homepage/icons/0343_NUT_PP_Icons_111422_Feed.png" alt="Rewards" />
                  </div>
                  <p>Rewards for all your Nutrena Purchases</p>
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="text-center">
                  <div className="px-3 mt-5 mb-4">
                    <img className="w-100" src="/public/assets/images/homepage/icons/0343_NUT_PP_Icons_111422_Coupons.png" alt="Coupons" />
                  </div>
                  <p>Coupons</p>
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="text-center">
                  <div className="px-3 mt-5 mb-4">
                    <img className="w-100" src="/public/assets/images/homepage/icons/0343_NUT_PP_Icons_111422_Sweeps.png" alt="win sweepstakes" />
                  </div>
                  <p>Chances to win sweepstakes</p>
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="text-center">
                  <div className="px-3 mt-5 mb-4">
                    <img className="w-100" src="/public/assets/images/homepage/icons/0343_NUT_PP_Icons_111422_Merch.png" alt="Exclusive" />
                  </div>
                  <p>Exclusive Nutrena merchandise</p>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className="easy_rewards page-section">
          <Container>
            <h2>It is easy to start getting rewards. See how it works.</h2>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <ol>
                  <li>Register for Plaid Perks</li>
                  <li>Complete activities and get points</li>
                  <li>Redeem rewards with your points</li>
                </ol>
                <div className="ml-4 mt-3 mb-5">
                  <a href="/login" className="btn btn-primary">
                    Log In
                  </a>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 pt-sm-5">
                {/* <img className="col pt-3" src="/public/assets/images/homepage/PP_thumbnail_640x412.png" alt="" /> */}
                {/* <div className="embed-responsive embed-responsive-16by9">
								<iframe className="embed-responsive-item" src="/public/assets/videos/PlaidPerks640x412.mp4" allowfullscreen></iframe>
							</div> */}
                <div className="embed-responsive embed-responsive-16by9">
                  <video controls poster="/public/assets/images/homepage/PP_thumbnail_640x412.png">
                    <source src="/public/assets/videos/PlaidPerks640x412.mp4" type="video/mp4" />
                    Video element not supported by your browser
                  </video>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className="get-access-section">
          <div className="heading-col">
            <Container>
              <h2 className=" mt-sm-5">Get access to Plaid Perks rewards</h2>
              <p>You will be able to redeem the points you earn for great rewards, from a variety of coupons towards Nutrena products, to exclusive Nutrena merchandise. Here s just a few of the great rewards you ll be able to choose from:</p>
            </Container>
          </div>
          <Carousel responsive={responsive} showDots={true} infinite={false} keyBoardControl={true}>
            <div>
              <img className="col-sm-12 px-0" src="/public/assets/images/homepage/products/0343_NUT_PP_Splsh_Rwrds_Crsl_112222-CoffeeMug.png" />
            </div>
            <div>
              <img className="col-sm-12 px-0" src="/public/assets/images/homepage/products/0343_NUT_PP_Splsh_Rwrds_Crsl_112222-Cooler.png" />
            </div>
            <div>
              <img className="col-sm-12 px-0" src="/public/assets/images/homepage/products/0343_NUT_PP_Splsh_Rwrds_Crsl_112222-DogToy.png" />
            </div>
            <div>
              <img className="col-sm-12 px-0" src="/public/assets/images/homepage/products/0343_NUT_PP_Splsh_Rwrds_Crsl_112222-Trucker_Hat.png" />
            </div>
            <div>
              <img className="col-sm-12 px-0" src="/public/assets/images/homepage/products/0343_NUT_PP_Splsh_Rwrds_Crsl_112222-Soft_Brush.png" />
            </div>
            <div>
              <img className="col-sm-12 px-0" src="/public/assets/images/homepage/products/0343_NUT_PP_Splsh_Rwrds_Crsl_112222-Sweatshirt.png" />
            </div>
            <div>
              <img className="col-sm-12 px-0" src="/public/assets/images/homepage/products/0343_NUT_PP_Splsh_Rwrds_Crsl_112222-Vest.png" />
            </div>
          </Carousel>
        </div>
        <div className="feed-it_section">
          <div className="feed-it_background">
            <Container>
              <div className="feed-it_featured"></div>
              <div className="row">
                <div className="head-col">
                  <h2 className="text-left">
                    Donate points. <br />
                    Support life-changing animal organizations.
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="text-col">
                  <p>If you love animals as much as we do, check out our Feed It Forward giving platform. Help us support life changing animal organizations by making a donation to the cause with your extra Plaid Perks points.</p>
                  <a className="btn  btn-secondary" href="https://www.feeditforward.org/" target="_blank">
                    See how we Feed It Forward
                  </a>
                </div>
              </div>
            </Container>
          </div>
        </div>
        <div className="twin-section ">
          <Container>
            <div className="row">
              <div className="col-sm-6 goto_faq-section">
                <div className="">
                  <div className="">
                    <h2>Learn more about Plaid Perks.</h2>
                    <a href="/faq" className="btn btn-primary">
                      Go to FAQs
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 start_earning_section">
                <div className="">
                  <div className="">
                    <h2>Ready to start earning points?</h2>
                    <a href="/login" className="btn btn-primary">
                      Log In
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

//export default IsLoadingHOC(Home, 'Wait .....');
export default IsLoadingHOC(Home, 'Wait .....');
