import React, { useEffect, useState } from 'react';
import Card from './Card';
import { connect, useSelector } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { portalPointAction, whatsNewActivitiesAction, getCompletedActivity, pointsUpdate } from '../redux/actions';
import { Button, Container, Col, Row, Modal } from 'react-bootstrap';
import IsLoadingHOC from '../components/IsLoadingHOC';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Contests from '../components/Contests';

const ChildComponent = props => {
  const { checkList, activityClick } = props;
  const user = useSelector(({ user }) => user);
  const [data, setData] = useState([]);
  const [viewMore, setViewMore] = useState(false);
  const [page, setChildPage] = useState(0);
  const pagesize = props.limit ? props.limit : 4;
  const [itemOffset, setItemOffset] = useState(0);
  useEffect(() => {
    let endOffset = 0;
    endOffset = itemOffset + pagesize;
    setItemOffset(endOffset);
    let pagi_data = props.data.slice(0, endOffset);
    setData(pagi_data);
    //console.log(page,endOffset,props.data.length);
    if (props.data && props.data.length > endOffset) {
      setViewMore(true);
    } else {
      setViewMore(false);
    }
  }, [page]);
  return (
    <div className="post-login">
      <Container fluid>
        <h2>
          <Trans>{props.title ? props.title : 'EARN POINTS WITH NEW OFFERS'}</Trans>
        </h2>
      </Container>
      <Container fluid>
        <Row xs={1} md={2} lg={4} className="card_holder">
          {data.map((item, index) => {
            return item.elementType && item.elementType === 'contest' ? (
              <Contests isHome={true} contestData={[item]} />
            ) : item.elementType && item.elementType === 'reward' ? (
              <Col>
                <Card>
                  <div onClick={e => activityClick(item, `/reward-preview/${item.id}`)} className="activity-block">
                    <div className="activity-img-holder d-flex align-items-center">
                      <img src={item.image} alt={item.itemName} style={{ width: '100%' }} />
                    </div>
                    <div className="activity-info">
                      <div className="points">
                        <Trans>Points</Trans>: {item.redeemPoints}
                      </div>
                      <h5>{item.itemName}</h5>
                    </div>
                    <div className="row justify-content-center">
                      <Button id="btn-id" className="cart-button btn btn-primary col-10" disabled={user.pointBalance < item.redeemPoints || (item.balance && item.balance <= 0) ? true : false}>
                        {item.balance && item.balance <= 0 ? 'Sold Out' : `${item.redeemPoints} points`}
                      </Button>
                    </div>
                  </div>
                </Card>
              </Col>
            ) : (
              <Col key={index}>
                <Card>
                  <div onClick={e => activityClick(item, `/earnpoints-action-preview/${item.id ? item.id : item._id}`)} className="activity-block">
                    <div className="activity-img-holder d-flex align-items-center">
                      {checkList.includes(item.id ? item.id : item._id) && (
                        <div className="activity-check">
                          <input type="checkbox" checked readOnly />
                          <label htmlFor="checkbox"></label>
                        </div>
                      )}
                      <img src={item.cardHeaderImage} alt="Avatar" style={{ width: '100%' }} />
                    </div>
                    <div className="activity-info">
                      {item.points > 0 && (
                        <div className="points">
                          <Trans>Points</Trans>: {item.points}
                        </div>
                      )}
                      <h5>{item.title}</h5>
                    </div>
                    <div className="row justify-content-center">
                      {!checkList.includes(item.id ? item.id : item._id) && props.section !== 'PlaidStoriesNews' && (
                        <Button activity-type={item.actionType} id={`earn-btn-${item.id ? item.id : item._id}`} className="cart-button btn btn-primary col-10">
                          <Trans>{item.title.toLowerCase().indexOf('memorial day points bonus') >= 0 ? `Start Earning` : item.actionType === 'refer' ? `Refer Now` : item.title.toLowerCase().indexOf('count 50% more points') >= 0 ? 'Enter Now' : `Earn Now`}</Trans>
                        </Button>
                      )}
                      {checkList.includes(item.id ? item.id : item._id) && props.section !== 'PlaidStoriesNews' && (
                        <Button disabled className="cart-button btn btn-primary col-10">
                          <Trans>{`Earned +${item.points} points`}</Trans>
                        </Button>
                      )}
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
        {viewMore && (
          <div className="text-center col mt-3 mb-5">
            {' '}
            <Button variant="secondary" id="view-more-earn-pointlist-btn" className="viewMore" onClick={() => setChildPage(page + 1)}>
              <Trans>View More</Trans>
            </Button>{' '}
          </div>
        )}
      </Container>
    </div>
  );
};

const PortalPointList = props => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [popupShow, setPopupShow] = useState(false);
  const [viewMore, setViewMore] = useState(true);
  const [page, setPage] = useState(0);
  const [pageRedirect, setPageRedirect] = useState(false);
  const pagesize = props.pagesize ? props.pagesize : 4;
  const [dataAllAbout, setDataAllAbout] = useState([]);
  const [dataCatchup, setDataCatchup] = useState([]);
  const [dataConnectWith, setDataConnectWith] = useState([]);
  const [idList, setIdList] = useState([]);
  const [checkList, setCheckList] = useState([]);
  const [whatsNew, setWhatsNew] = useState([]);
  const [plaidStories, setPlaidStories] = useState([]);
  const [activityStatus, setActivityStatus] = useState({ status: false, activityEventId: '', activityData: {} });
  useEffect(() => {
    if (props.section === 'WhatsNew') {
      loadWhatsNewList();
    } else {
      getNextData(0);
    }
    // eslint-disable-next-line
  }, []);
  const loadWhatsNewList = () => {
    props.setLoading(true);
    //    whatsNewActivitiesAction('contest,activity,reward')
    whatsNewActivitiesAction('contest,reward')
      .then(response => {
        if (response.data) {
          let actData = [],
            contestData = [],
            rewardData = [];
          if (response.data.activityResult) {
            actData = response.data.activityResult;
          }
          if (response.data.contestResult) {
            contestData = response.data.contestResult;
          }
          if (response.data.rewardResult) {
            rewardData = response.data.rewardResult;
          }
          actData = actData.map(v => {
            return { ...v, elementType: 'activity', dt: new Date(v.createdAt) };
          });
          contestData = contestData.map(v => {
            return { ...v, elementType: 'contest', dt: new Date(v.createdAt) };
          });
          rewardData = rewardData.map(v => {
            return { ...v, elementType: 'reward', dt: new Date(v.createdAt) };
          });
          let allData = actData.concat(contestData, rewardData);
          allData.sort((v1, v2) => v2.dt - v1.dt);
          setWhatsNew(allData);
          let activityList = actData;
          if (activityList) {
            let activityId = activityList.map(v => (v.id ? v.id : v._id));
            setIdList(activityId);
          }
        }
        props.setLoading(false);
      })
      .catch(ex => {
        props.setLoading(false);
        setWhatsNew([]);
      });
  };
  useEffect(() => {
    let activityList = data;
    if (activityList && props.page && props.page === 'home' && props.section === 'WhatsNew') {
      //Home - What's New Actity
      // let whatsNewData = activityList.filter(v => {
      //   if (v.actionType !== 'click' || (v.channel === 'other' && (/^http/.test(v.data) === false || v.points > 0))) {
      //     let o = Object.assign({}, v);
      //     if (o.isTierEnabled && o.tierPoints) {
      //       o.points = o.tierPoints;
      //     }
      //     return o;
      //   }
      // });
      // setWhatsNew(whatsNewData);

      //Home - PLAID STORIES & NEWS
      let PlaidStoriesData = activityList.filter(v => {
        if (v.actionType === 'click' && v.channel === 'other' && /^http/.test(v.data) === true && (v.points === null || v.points === 0)) {
          let o = Object.assign({}, v);
          if (o.isTierEnabled && o.tierPoints) {
            o.points = o.tierPoints;
          }
          return o;
        }
      });
      setPlaidStories(PlaidStoriesData);
    }

    if (activityList && props.page && props.page === 'earnpoints') {
      //refer id find
      const refId = activityList.filter(v => v.actionType === 'refer');
      if (refId.length > 0) {
        //setReferFriend(refId[0]['id'])
      }

      //All About You And Your Animals
      let allAboutData = activityList.filter(v => {
        if (v.actionType !== 'click') {
          let o = Object.assign({}, v);
          if (o.isTierEnabled && o.tierPoints) {
            o.points = o.tierPoints;
          }
          return o;
        } else if (v.actionType === 'click' && v.channel === 'other' && /^http/.test(v.data) === false && Number(v.points) === 0) {
          let o = Object.assign({}, v);
          if (o.isTierEnabled && o.tierPoints) {
            o.points = o.tierPoints;
          }
          return o;
        }
      });
      setDataAllAbout(allAboutData);

      //When  points > 0
      activityList = activityList.filter(v => v.points > 0);

      //Catch Up on Nutrena Content
      let cathUpData = activityList.filter(v => {
        if (v.actionType === 'click' && v.channel === 'other') {
          let o = Object.assign({}, v);
          if (o.isTierEnabled && o.tierPoints) {
            o.points = o.tierPoints;
          }
          return o;
        }
      });
      setDataCatchup(cathUpData);

      //Connect With Us
      let connect_data = activityList.filter(v => {
        if (v.actionType === 'click' && v.channel !== 'other') {
          let o = Object.assign({}, v);
          if (o.isTierEnabled && o.tierPoints) {
            o.points = o.tierPoints;
          }
          return o;
        }
      });
      setDataConnectWith(connect_data);
    }
  }, [data]);
  useEffect(() => {
    if (idList.length > 0) {
      fetchData(idList);
    }
  }, [idList]);
  const fetchData = async idList => {
    try {
      let completeActivity = await getCompletedActivity(idList);
      if (completeActivity.data && completeActivity.data.length > 0) {
        let chk = completeActivity.data.filter(v => v.count > 0).map(v => v.activityId);
        setCheckList(chk);
      }
    } catch (ex) {
      console.log('error ', ex);
    }
  };
  useEffect(() => {
    if (activityStatus.status) {
      clickEarnCompleted();
      setActivityStatus({ status: false, activityEventId: '', activityData: {} });
    }
  }, [activityStatus]);

  const getNextData = page => {
    const actionType = ['view', 'survey', 'read', 'share', 'click'];
    //const actionType = ['view', 'survey', 'refer', 'read', 'share', 'click'];

    props.setLoading(true);
    props
      .portalPointAction(page + 1, actionType, pagesize)
      .then(response => {
        let activityList = response.data;
        if (activityList) {
          let activityId = activityList.map(v => v.id);
          setIdList(activityId);
          activityList = activityList.map(v => {
            let o = Object.assign({}, v);
            if (o.isTierEnabled && o.tierPoints) {
              o.points = o.tierPoints;
            }
            return o;
          });
        }
        setData([...data, ...activityList]);
        setPage(page + 1);
        setViewMore(response.end);
        props.setLoading(false);
      })
      .catch(ex => {
        console.log('Error In API Call', ex);
        props.setLoading(false);
      });
  };

  const clickEarnInit = async objParam => {
    let { id, points, data: socialURL, maxAttempt, times } = objParam;
    if (socialURL) {
      window.open(socialURL, '_blank');
    }
    let data = {
      reference: id,
      state: 'started',
    };
    props.setLoading(true);
    props
      .pointsUpdate(data, 'post')
      .then(responseData => {
        props.setLoading(false);
        if (responseData['data']) {
          setActivityStatus(Object.assign({}, activityStatus, { status: true, activityEventId: responseData.data.id, activityData: objParam }));
        } else {
          setActivityStatus(Object.assign({}, activityStatus, { status: false, activityEventId: 'none', activityData: objParam }));
        }
      })
      .catch(err => {
        setActivityStatus(Object.assign({}, activityStatus, { status: false, activityEventId: 'none', activityData: objParam }));
        props.setLoading(false);
        let msg = '';
        if (err['errors'] && err['errors'][0]['message']) {
          msg = `${t('Sorry')}! ${t(err['errors'][0]['message'])}.`;
        } else {
          msg = `${t('Sorry')}! ${t("You've earned the maximum points possible for this action")}.`;
        }
        msg = `${t('Sorry')}! ${t("You've earned the maximum points possible for this action")}.`;
        toast.error(msg);
      });
  };
  const clickEarnCompleted = () => {
    let data = {
      id: activityStatus.activityEventId,
      state: 'completed',
    };
    if (activityStatus.activityEventId !== '') {
      props
        .pointsUpdate(data, 'put', 'read')
        .then(responseData => {
          if (responseData['data']) {
            toast.success(`${t('Congratulations')}! ${t('You have earned')} ${activityStatus.activityData.points} ${t('Points')}.`);
          } else {
            toast.error(`${t('Sorry')}! ${t("You've earned the maximum points possible for this action")}.`);
          }
        })
        .catch(err => {
          if (activityStatus.activityEventId === 'none') {
            toast.error(`${t('Sorry')}! ${t("You've earned the maximum points possible for this action")}.`);
          } else {
            let msg = '';
            if (err['errors'] && err['errors'][0]['message']) {
              msg = `${t('Sorry')}! ${t(err['errors'][0]['message'])}.`;
            } else {
              msg = `${t('Sorry')}! ${t("You've earned the maximum points possible for this action")}.`;
            }
            msg = `${t('Sorry')}! ${t("You've earned the maximum points possible for this action")}.`;
            toast.error(t(msg || 'Something went wrong'));
          }
          //console.log("Response Error:", JSON.stringify(err));
        });
    } else {
      toast.error(`${t('Sorry')}! ${t("You've earned the maximum points possible for this action")}.`);
    }
  };

  const activityClick = async (activityData, redirectUrl) => {
    try {
      if (activityData.actionType === 'refer') {
        setPageRedirect('/referfriend');
      } else if (activityData['elementType'] === 'reward') {
        setPageRedirect(redirectUrl);
      } else {
        if (activityData['actionType'] === 'click' && activityData['channel'] === 'other' && (activityData['points'] === null || activityData['points'] === 0) && /^http/.test(activityData['data']) === true) {
          window.open(activityData['data'], '_blank');
        } else if (activityData['actionType'] === 'click' && activityData['points'] > 0) {
          clickEarnInit(activityData);
        } else {
          setPageRedirect(redirectUrl);
        }
      }
    } catch (err) {
      console.log('Error in Activity status check', err);
    }
  };
  const handlePopupClose = () => {
    setPopupShow(false);
  };
  if (pageRedirect) {
    return <Redirect push to={pageRedirect} />;
  } else {
    return (
      <Container>
        {!props.page && (
          <div className="post-login">
            <Container>
              <h2>
                <Trans>{props.title ? props.title : 'EARN POINTS WITH NEW OFFERS'}</Trans>
              </h2>
            </Container>
            <div>
              <Row xs={1} md={2} lg={4} className="card_holder">
                {data.map((item, index) => {
                  return (
                    <Col key={index}>
                      <Card>
                        <div onClick={e => activityClick(item, `/earnpoints-action-preview/${item.id}`)} className="activity-block">
                          <div className="activity-img-holder d-flex align-items-center">
                            {checkList.includes(item.id) && (
                              <div className="activity-check">
                                <input type="checkbox" checked readOnly />
                              </div>
                            )}
                            <img src={item.cardHeaderImage} alt="Avatar" style={{ width: '100%' }} />
                          </div>
                          <div className="activity-info">
                            {item.points > 0 && (
                              <div className="points">
                                <Trans>Points</Trans>: {item.points}
                              </div>
                            )}
                            <h5>{item.title}</h5>
                          </div>
                          <div className="row justify-content-center">
                            {item.actionType !== 'refer' && (
                              <Button activity-type={item.actionType} id={`earn-btn-${item.id}`} className="cart-button btn btn-primary col-10">
                                <Trans>Earn Now</Trans>
                              </Button>
                            )}
                          </div>
                        </div>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
              {viewMore && (
                <div className="text-center col my-4">
                  {' '}
                  <Button variant="secondary" id="view-more-earn-pointlist-btn" className="viewMore" onClick={() => getNextData(page)}>
                    <Trans>View More</Trans>
                  </Button>{' '}
                </div>
              )}
            </div>
          </div>
        )}
        {/* Home:What's New */}
        {props.page && props.page === 'home' && props.section === 'WhatsNew' && whatsNew.length > 0 && <ChildComponent activityClick={activityClick} checkList={checkList} data={whatsNew} next={true} limit={4} title="What's New" isHome={true} {...props} />}
        {/* Home:Plaid Stories & News */}
        {props.page && props.page === 'home' && props.section === 'PlaidStoriesNews' && plaidStories.length > 0 && <ChildComponent section={props.section} activityClick={activityClick} checkList={checkList} data={plaidStories} next={true} limit={4} title="Plaid Stories & News" />}

        {props.page && props.page === 'earnpoints' && dataAllAbout.length > 0 && <ChildComponent activityClick={activityClick} checkList={checkList} data={dataAllAbout} next={true} limit={4} title="All About You And Your Animals" />}
        {props.page && props.page === 'earnpoints' && dataCatchup.length > 0 && <ChildComponent activityClick={activityClick} checkList={checkList} data={dataCatchup} next={true} limit={4} title="Catch Up on Nutrena Content" />}
        {props.page && props.page === 'earnpoints' && dataConnectWith.length > 0 && <ChildComponent activityClick={activityClick} checkList={checkList} data={dataConnectWith} next={true} limit={4} title="Connect with Nutrena" />}

        <Modal show={popupShow} onHide={handlePopupClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Trans>Sorry</Trans>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="survey-message-div">
              <p className="alignCenter">
                <Trans>You have already earned the maximum number of points possible for this action</Trans>.
              </p>
            </div>
          </Modal.Body>
          <p align="center">
            <Link to={'#'} className="btn btn-link" onClick={handlePopupClose}>
              <Trans>Close this window</Trans>
            </Link>
          </p>
          <p align="center">
            <Link className="btn btn-link" onClick={handlePopupClose} to="/">
              <Trans>Go back to home</Trans>
            </Link>
          </p>
        </Modal>
      </Container>
    );
  }
};

export default connect(null, { portalPointAction, pointsUpdate })(IsLoadingHOC(PortalPointList, 'Loading....'));
