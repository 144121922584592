import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import TopRight from './TopRight';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Menu = ({ data }) => {
  const { t } = useTranslation();
  const user = useSelector(({ user }) => user);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      document.getElementById('navbarSupportedContent').classList.remove('show');
    });
    return window.removeEventListener('scroll', () => {});
  }, []);
  const navslide = () => {
    document.getElementById('navbarSupportedContent').classList.remove('show');
  };

  return (
    <Fragment>
      <button id="button-navbar-toggler" className="navbar-toggler mob-menu-pos order-4" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span id="span-navbar-toggler-icon" className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
          {!user.accessToken ? (
            <>
              <li className="nav-item">
                <Link className="nav-link" id="href-faq" to="/faq" onClick={navslide}>
                  {t('FAQ')}
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="href-support" href="https://www.nutrenaworld.com/plaid-perks-inquiry" target="_blank" onClick={navslide}>
                  {t('Support')}
                </a>
              </li>
            </>
          ) : (
            <>
              {/*[START:EARNPOINTS]*/}
              {/* <li className="nav-item">
                <Link className="nav-link" id="href-earn-points" to="/earnpoints" onClick={navslide}>
                  {t('Earn Points')}
                </Link>
              </li> */}
              {/*[END:EARNPOINTS]*/}
              {/*[START:RECEIPTUPLOAD]*/}
              {/* <li className="nav-item">
                <Link className="nav-link" id="href-receipt-upload" to="/upload-receipt" onClick={navslide}>
                  {t('Upload')}
                </Link>
              </li> */}
              {/*[END:RECEIPTUPLOAD]*/}
              {/*[START:REWARDS]*/}
              <li className="nav-item">
                <Link className="nav-link" id="href-rewards" to="/rewards" onClick={navslide}>
                  {t('Rewards')}
                </Link>
              </li>
              {/*[END:REWARDS]*/}

              {/*[START:CONTENT]*/}
              <li className="nav-item">
                <Link className="nav-link" id="href-contest" to="/contest" onClick={navslide}>
                  {t('Contest')}
                </Link>
              </li>
              {/*[END:CONTENT]*/}
              {/* <TopRight /> */}
            </>
          )}
        </ul>
        {/* My All-Access , Points History, My Rewards, Profile, Connect Movie ANYHERE, Logout */}
      </div>
    </Fragment>
  );
};

export default Menu;
