import api from '../../api/api.js';
export const rewardsListAction = (id = '', code = null) => {
  if (id === '') {
    return api.get(`/s1/userportal/rewardlist`, null);
  } else if (code && code !== '') {
    return api.get(`/s1/userportal/rewardlist?groupingCode=${code}`, null);
  } else {
    return api.get(`/s1/reward/campaign/${id}`, null);
  }
};
export const rewardsListTagWiseAction = tags => {
  return api.get(`/s1/userportal/rewardlist?tags=${tags}`, null);
};

export const pgTokenAction = () => {
  return api.get(`/s2/pg/pgToken`, null);
};
export const rewardsOrderPaymentAction = (id, body) => {
  return api.put(`/s2/rewards/order/${id}/checkout`, body);
};
export const redeemRewardOrderAction = (id, body = {}, isSFRequired = 1) => {
  return api.post(`/s2/rewards/campaign/redeem/${id}?isSFRequired=${isSFRequired}`, body);
};
export const rewardsOrderAddressUpdateAction = (id, body) => {
  return api.put(`/s2/rewards/order/address/${id}`, body);
};
export const downloadRewardsAction = (id, filename) => {
  return api.get(`/s2/rewards/order/download/${id}/${filename}`, null);
};
export const getMyRewardsAction = (pageNum = 0, pageSize = 5000) => {
  return api.get(`/s2/points/myreward/${pageNum}/${pageSize}`, null);
};
export const rewardsListUnSecureAction = () => {
  return api.post(`/s1/userportal/rewardlist`, {});
};
export const rewardOrderCompleteAction = (id, body, isSFRequired = 1) => {
  return api.post(`/s2/rewards/campaign/complete/${id}?isSFRequired=${isSFRequired}`, body);
};
