import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Modal, Card as Cards } from 'react-bootstrap';
import { getUserDetails } from '../../redux/actions';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import ProfileForm from '../Profile/ProfileForm';

function OnBoardingProfile({ getUserDetails, setLoading }) {
  const user = useSelector(({ user }) => user);
  const [showModal, setShowModal] = useState(false);
  const confige = useSelector(state => state.app.config);
  const [data, setData] = useState({});
  useEffect(() => {
    if (user.accessToken) {
      setLoading(true);

      getUserDetails(user.id)
        .then(response => {
          setLoading(false);
          if (confige.uiConfig) {
            let items = {};
            confige.uiConfig.userFields.forEach(e => {
              if (['birth'].indexOf(e.name) >= 0) {
                e.validation.mandatory = true;
              }
              if (e.validation.mandatory) {
                items[e.name] = true;
              }
            });

            let {
              data: {
                additionalInfo: { activity_tags_list },
              },
            } = response;
            //for testing purpose
            // response.data.firstName =""
            // activity_tags_list = ""
            for (let key in response.data) {
              if (key !== 'birth' && items[key] && !response.data[key]) {
                setShowModal(true);
                break;
              }
              if (key === 'birth' && items[key] && !response.data[key] && response.data?.additionalInfo?.birthNotDisclose !== 'true') {
                setShowModal(true);
                break;
              }
            }
            if (!activity_tags_list || activity_tags_list === '') {
              setShowModal(true);
            }
          }

          setData({ ...response.data });
        })
        .catch(error => {
          setLoading(false);
          console.log('error', error);
        });
    }
  }, [user.accessToken]);

  if (user.accessToken) {
    return (
      <Modal show={showModal} backdrop="static" size="xl" onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>Please complete your account Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <form role="form">
              <ProfileForm hideModal={setShowModal} userdata={data} />
            </form>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  return <div />;
}

export default connect(null, { getUserDetails })(IsLoadingHOC(OnBoardingProfile, 'Wait .....'));
