import React from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { connect } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHistory,Link } from "react-router-dom";
import { forgetPassword } from "../../redux/actions";
import { Trans, useTranslation} from "react-i18next";
const ForgetPassword = ({ setLoading, login, forgetPassword }) => {
	const history = useHistory();
	const { t } = useTranslation();
	return (
		<div className="page">
			<div className="container">
				<div className="page-section">
					<div className="row justify-content-center">
						<h1 className="col-12 text-center">Forgot Password?</h1>
						<div className="col-md-6 col-sm-10">
							<Formik
								initialValues={{ email: "" }}
								validationSchema={Yup.object({
									email: Yup.string()
										.required(`${t("lang_key_username")} / ${t("lang_key_email")} ${t("Should not be empty")}.`),
								})}
								validate={values => {
									const errors = {};
									if (!values.email) {
									errors.email = 'Email address is required.';
									} else if (
									!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
									) {
									errors.email = 'Email address is invalid.';
									}
									return errors;
								}}
								onSubmit={(values, { setSubmitting }) => {
									//console.log(values);
									setLoading(true);
									forgetPassword(values.email)
										.then((response) => {
											console.log(response);
											setSubmitting(false);
											setLoading(false);
											//toast.success(response.message);
											toast.success('Password reset link has been sent to your email. Please check your email.');
											history.push("/login");
										})
										.catch((error) => {
											console.log(error);
											setSubmitting(false);
											setLoading(false);
											error.forEach((item) => {
												toast.error(item.message);
											});
										});

									// setTimeout(() => {
									// 	alert(JSON.stringify(values, null, 2));
									// 	setSubmitting(false);
									// }, 400);
								}}>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
									/* and other goodies */
								}) => (
									<Form role="form">
										<p className="text-center p_note">Please provide your email address. We will send you a link to reset your password.</p>
										<div className="form-group row mt-5">
											<label htmlFor="staticEmail" className="col-sm-12 col-form-label">
												<Trans>Email Address</Trans>
											</label>
											<div className="col-sm-12">
												<Field data-test="reset-password" 
												name="email" 
												value={values?.email}
												type="text" 
												id="staticEmail"
												className="form-control"
												pleaceholder="Enter registered email/username"
												aria-required="true"
												/>
												<span className="input-error" role="alert"><ErrorMessage name="email" /></span>
												{/* <input data-test="reset-password" type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)}  className="form-control" id="staticEmail" placeholder="Email" /> */}
											</div>
										</div>
										
										<div className="col-sm-12 pt-4 text-center mb-5">									
											
											<button data-test="reset-password-button" 
												type="submit" 
												className="btn btn-primary text-center"
												disabled={values.email.length>0 && Object.values(errors).length ===0 && !isSubmitting?false:true}
											>
												<Trans>Submit</Trans>
											</button>
										</div>
										<div className="text-center">
												<Link to="/login" className="text-link-plain">
													<Trans>Back to login</Trans>
												</Link>
											</div>
									</Form>
								)}
								
							</Formik>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default connect(null, { forgetPassword })(IsLoadingHOC(ForgetPassword, "Wait ...."));
