import React, { useState } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import { connect, useSelector } from 'react-redux';
import errorHtml from '../../components/Error';
import { login } from '../../redux/actions';
import { Link, useHistory } from 'react-router-dom';
import SocialLogin from '../../components/SocialLogin';
import { Trans, useTranslation } from 'react-i18next';
import SecondFactorAuth from '../../components/SecondFactorAuth';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import Captcha from '../../components/CaptchaClick';
const Login = ({ setLoading, login }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const confige = useSelector(state => state.app.config);
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [remember_me, setRememberMe] = useState(false);
  const [showSecondFactorPopup, setShowSecondFactorPopup] = useState(false);
  const [secondFactorAuthData, setSecondFactorAuthData] = useState({});
  const [errors, setErrors] = useState({ username: '', password: '', captchaVal: '' });
  const [fpData, setFpData] = useState({ visitorId: '', requestId: '' });
  let [captchaValue, setCaptchaValue] = useState(false);
  let [captchaEnable, setCaptchaEnable] = useState(false);
  const onChangeHandle = e => {
    const { name, value } = e.target;
    if (name === 'username') {
      setUserName(e.target.value);
      value.length < 1 ? setErrors({ ...errors, username: `${t('This information is required')}.` }) : setErrors({ ...errors, username: '' });
    }
    if (name === 'password') {
      value.length < 1 ? setErrors({ ...errors, password: `${t('A password is required')}.` }) : setErrors({ ...errors, password: '' });
      setPassword(e.target.value);
    }
  };
  const [redirectUrl, setRedirectUrl] = useState('/');
  let [passwordViewOpt, setPasswordViewOpt] = useState({ password: false });
  React.useEffect(() => {
    const rmCheck = document.getElementById('rememberMe'),
      emailInput = document.getElementById('staticEmail');

    if (localStorage.rmCheck && localStorage.rmCheck !== '') {
      rmCheck.setAttribute('checked', 'checked');
      emailInput.value = localStorage.username;
      setUserName(localStorage.username);
      setPassword(localStorage.password);
      setRememberMe(true);
    } else {
      rmCheck.removeAttribute('checked');
      emailInput.value = '';
    }
  }, []);

  React.useEffect(() => {
    if (confige.fingerprinting) {
      if (confige.fingerprinting.enable) {
        const fpPromise = FingerprintJS.load({ apiKey: confige.fingerprinting.clientKey });
        // Get the visitor identifier when you need it.
        fpPromise
          .then(fp => fp.get())
          .then(result => {
            setFpData({ visitorId: result.visitorId, requestId: result.requestId });
          });
      }
    }
    if (confige.captchaConfig) {
      if (confige.captchaConfig.loginCaptcha) {
        setCaptchaEnable(true);
      }
    }
  }, [confige]);
  React.useEffect(() => {
    let errorArray = JSON.parse(JSON.stringify(errors));
    if (captchaValue) {
      errorArray['captchaVal'] = '';
    }
    setErrors(errorArray);
    // eslint-disable-next-line
  }, [captchaValue]);
  React.useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const redtUrl = query.get('route') ? query.get('route') : '/';
    if (redirectUrl !== redtUrl) {
      setRedirectUrl(redtUrl);
    }
    // eslint-disable-next-line
  }, [window.location]);
  const handleError = () => {
    let value = false;
    const error = {};
    if (username.length < 1) {
      error.username = `${t('This information is required')}.`;
      value = true;
    }
    if (password.length < 1) {
      error.password = `${t('A password is required')}.`;
      value = true;
    }
    if (!captchaValue && captchaEnable) {
      error.captchaVal = `${t('Please select captcha')}.`;
      value = true;
    }
    return { value, error };
  };

  const onSubmit = () => {
    const { value, error } = handleError();
    if (value) {
      setErrors({ ...errors, ...error });
      return false;
    }
    setLoading(true);
    let requestBody = { username, password, visitorId: fpData.visitorId, requestId: fpData.requestId };
    if (captchaEnable) {
      requestBody['captchaVal'] = captchaValue;
    }

    login(requestBody)
      .then(response => {
        setLoading(false);
        if (response.httpCode === 204) {
          setSecondFactorAuthData({ id: response.data });
          setShowSecondFactorPopup(true);
        } else if (response.httpCode === 205) {
          history.push(`/reset-password?lgu=1&token=${response.data.token}`);
          toast.success(`${t('Please create new password')}.`);
        } else {
          //Call remember fn
          lsRememberMe(remember_me, username, password);
          toast.success(`${t('User login successfully')}.`);
          history.push(redirectUrl);
        }
      })
      .catch(error => {
        console.log('Error in login', error);
        if (error['httpCode'] === 403) {
          toast.error(`${t('Your account is suspended. Please contact helpdesk for more information')}.`);
        } else if (error['httpCode'] === 406) {
          toast.error(`${t('Error')}! ${t('User not verified')}.`);
        } else {
          let errorMessage = (error && error.errors && error.errors[0]['message']) || 'Invalid credentials.';
          toast.error(`${t(errorMessage)}`);
        }
        setLoading(false);
      });
  };

  function lsRememberMe(rmCheck, username, pass) {
    if (rmCheck && username !== '' && pass !== '') {
      localStorage.username = username;
      localStorage.password = pass;
      localStorage.rmCheck = rmCheck;
    } else {
      localStorage.password = '';
      localStorage.username = '';
      localStorage.rmCheck = '';
    }
  }

  return (
    <div className="page">
      <div className="container">
        <div className="page-section">
          <h1 className="main-heading">
            <Trans>Log In</Trans>
          </h1>
          <div className="row justify-content-center">
            <div className="col-md-6 col-sm-10">
              <form role="form">
                <div className="form-group row">
                  <label htmlFor="staticEmail" className="col-sm-12 col-form-label">
                    {/* <Trans>lang_key_username</Trans>/<Trans>lang_key_email</Trans> */}
                    <Trans>Email Address or Username</Trans>
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      data-test="sign-in-username-input"
                      name="username"
                      value={username}
                      onChange={onChangeHandle}
                      className="form-control"
                      id="staticEmail"
                      // placeholder={`${t("Enter ")}${t("lang_key_email")}/${t("lang_key_username")}`}
                      aria-autocomplete="email"
                    />
                    <span className="input-error" role="alert">
                      {errors.username}
                    </span>
                  </div>
                </div>
                {/*[START:PASSWORD]*/}
                <div className="form-group row">
                  <label htmlFor="inputPassword" className="col-sm-12 col-form-label">
                    <Trans>password</Trans>
                  </label>

                  <div className="col-sm-12 input-group">
                    <input
                      type={!passwordViewOpt.password ? 'password' : 'text'}
                      data-test="sign-in-password-input"
                      name="password"
                      value={password}
                      onChange={onChangeHandle}
                      className="form-control"
                      id="inputPassword"
                      // placeholder={`${t("Enter ")}${t("password")}`}
                      onKeyPress={e => e.key === 'Enter' && onSubmit()}
                      aria-describedby="Password-btn"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-light form-control pl-3"
                        type="button"
                        id="password-btn"
                        onClick={() => {
                          setPasswordViewOpt({ ...passwordViewOpt, password: !passwordViewOpt.password });
                        }}>
                        {!passwordViewOpt.password ? 'Show' : 'Hide'}
                        {/* <span className="material-symbols-outlined">{!passwordViewOpt.password ? 'visibility_off' : 'visibility'}</span> */}
                      </button>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <span className="input-error" role="alert">
                      {errors.password}
                    </span>
                  </div>
                </div>
                {/*[END:PASSWORD]*/}
                <div className="row">
                  <div className="col-sm-6">
                    {/* [START:REMEMBER ME] */}
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" name="remember_me" onClick={e => setRememberMe(e.target.checked)} value={remember_me} id="rememberMe" />
                      <label className="form-check-label" htmlFor="rememberMe">
                        Remember me
                      </label>
                    </div>
                    {/* [END:REMEMBER ME] */}
                  </div>
                </div>

                {/*[START:GOOGLECAPTCHA]*/}
                {captchaEnable ? (
                  <div className="form-group row">
                    <label htmlFor="g-recaptcha-response" className="col-sm-12 invisible" aria-hidden="true">
                      Google captcha
                    </label>
                    <div className="col-sm-12">
                      <Captcha align="left" reset={0} parentCallback={setCaptchaValue} />
                      <span className="input-error" role="alert">
                        {errors.captchaVal}
                      </span>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {/*[END:GOOGLECAPTCHA]*/}

                <div className="col-md-12 text-center mt-5 mb-4">
                  <button onClick={onSubmit} data-test="sign-in-sign-in-button" type="button" className="btn btn-primary px-5" disabled={!(username.length > 0 && password.length > 0)}>
                    <Trans>login</Trans>
                  </button>
                </div>
                <div className="my-4">
                  {/* FORGOT PASSWORD */}
                  <div className="text-center pt-4">
                    Forgot Password? Click{' '}
                    <Link to="forget-password" className="span-text-link">
                      <Trans>here</Trans>
                    </Link>
                    .
                  </div>
                  {/* END FORGOT PASSWORD */}
                </div>

                <div className="my-3">
                  <SocialLogin />
                </div>
                {/* <div className="register-links text-center mt-3">
                  Not a member yet? Sign up{' '}
                  <Link to="register" className="span-text-link">
                    <Trans>here</Trans>
                  </Link>
                  .
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showSecondFactorPopup}>
        <Modal.Body>{showSecondFactorPopup ? <SecondFactorAuth otpLen={6} waitingTime={120} data={secondFactorAuthData} /> : ''}</Modal.Body>
      </Modal>
    </div>
  );
};

export default connect(null, { login })(IsLoadingHOC(Login, 'Wait ....'));
